.accountOutlet .flexDiv {
  display: flex;
}

.flexDiv form {
  width: 100%;
}

.accountOutlet .flexDiv img {
  width: 100%;
  object-fit: contain;
}

.accountOutlet form input {
  padding-right: 44px;
}

@media (max-width: 1200px) {
  .flexDiv img {
    display: none;
  }
}
