.selectContainer {
  width: 40%;
  margin-right: 16px;
}

.select {
  /* width: 24rem; */
  width: 100%;
  height: 45px;
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  background-color: #ffffff;
  border: 2px solid #d6d6d6;
  border-radius: 7px;
  padding-left: 1rem;
  outline: none;
}

.disabled {
  color: #808080;
  background-color: rgb(241, 241, 241);
  border: 2px solid gainsboro;
}

.label {
  color: #808080;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;
}

.selectDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
}

.selectDivInline {
  display: flex;
  align-items: center;
  position: relative;
}

.selectDivInline .label {
  min-width: 75px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.selectDivInline .select {
  padding: 0rem 0.3rem;
}

.selectDivInline .select:hover {
  cursor: pointer;
}

.selectDiv svg,
.selectDivInline svg {
  width: 1.1rem;
  margin-right: 10px;
  color: #808080;
}

.selectError {
  color: #db0b0b;
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

@media (max-width: 698px) {
  .select {
    width: 100% !important;
  }

  .selectDiv {
    width: 100%;
    margin-right: 0px !important;
  }
}

@media (max-width: 1200px) {
  .select {
    width: 100% !important;
  }

  .selectDiv {
    width: 100%;
    margin-right: 0px !important;
  }
}
