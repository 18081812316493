/* Top Sellling Sections */
.topSelling {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.topSelling .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: fit-content;
}

:root {
  --swiper-navigation-color: #22135a;
  opacity: 1 !important;
  --swiper-navigation-size: 1rem !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #fff;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  padding: 1rem;
  font-weight: 600;
}

.swiper-button-next {
  right: 6px !important;
  z-index: 100;
}

.swiper-button-prev {
  left: 6px !important;
  z-index: 100;
}

@media (max-width: 698px) {
  /* Top Selling Sectons */
  .topSelling {
    margin-top: 1rem;
  }

  .cards {
    width: 13rem;
    height: 18rem;
  }

  .cards .img-div img {
    padding-top: 1rem;
    max-width: 95%;
    max-height: 95%;
  }
  .cards .details h2 {
    font-size: 1.1rem;
  }

  .cards .details h2 span {
    font-size: 0.9rem;
    margin: 0.6rem 0rem 0.3rem 0.4rem;
  }
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  /* column-gap: 1.5rem; */
  margin-top: 1rem;
  justify-content: flex-start;
  row-gap: 1.5rem;
}

.no-slider {
  row-gap: 0rem !important;
}

@media (max-width: 576px) {
  .no-slider {
    row-gap: 1.5rem !important;
  }
}

@media (max-width: 1200px) {
  .flexContainer {
    justify-content: center;
  }
}

.width-control {
  max-width: 280px;
  width: 100%;
}

@media (max-width: 1400px) {
  .width-control {
    max-width: 260px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .width-control {
    max-width: 220px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .width-control {
    max-width: 255px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .width-control {
    max-width: 500px;
    width: 100%;
  }
}

/* @media only screen and (max-width: 1200px) and (min-width: 698px) {
} */
