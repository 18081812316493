.cart-qty {
  display: flex;
  align-items: center;
  width: 8rem;
  margin-left: 16px;
}

@media (min-width: 698px) and (max-width: 766px) {
  .cart-qty {
    margin-left: 0px;
  }
}

@media (max-width: 576px) {
  .cart-qty {
    width: 6rem;
  }
}

.outlet .row p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #144580;
  margin-right: 2rem;
}

.outlet .products {
  margin: 1rem 0rem;
}

.outlet .clear-cart {
  cursor: pointer;
}

/* .outlet .subject {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  justify-content: flex-start;
} */

.outlet .subject label,
.outlet .subject p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

/* .outlet .products .subject p{
    margin-right: 17rem;
} */

.outlet .products .product {
  /* display: flex; */
  align-items: center;
  margin-bottom: 2rem;
}

.outlet input[type="checkbox"] {
  /* -webkit-appearance: none;
  appearance: none; */
  background-color: rgb(173, 173, 173);
  margin-right: 1rem;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 7px;
  cursor: pointer;
}

.outlet input[type="checkbox"]::after {
  content: "";
}

.outlet input[type="checkbox"]:checked {
  /* background-color: rgba(20, 69, 128, 1); */
  accent-color: hsl(213, 73%, 29%);
}

.outlet input[type="checkbox"]:hover {
  accent-color: hsl(213, 73%, 49%);
}

.outlet label {
  display: flex;
  width: 98%;
  align-items: center;
  justify-content: space-between;
}

.outlet .products .greyText {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
}

.outlet .products label .imgPrice {
  display: flex;
}

.outlet .products label img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 1rem;
}

.outlet .products label .product-img {
  cursor: pointer;
}

.outlet .products label .namePrice {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100px;
}

.outlet .products label .namePriceCart {
  display: flex;
  flex-direction: row;
  height: 100px;
}

.outlet .products label .name {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 16rem;
  color: #000000;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.outlet .products label .namePriceCart .name {
  width: 16rem !important;
}

@media (max-width: 425px) {
  .outlet .products label .namePriceCart .name {
    width: 12rem !important;
  }
}

@media (max-width: 768px) {
  .outlet .products label .name {
    width: 100%;
  }
}

@media (max-width: 766px) {
  .outlet .products label .namePriceCart {
    flex-direction: column;
  }
}

.outlet .products label .price {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-top: 8px;
  color: #757575;
}

.outlet .products label .single-price {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
}

.products label .wrapper {
  /* display: flex;
  align-items: center; */
  /* width: 16rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products label .wrapper .num {
  border: 2px solid #bebebe;
  border-radius: 5px;
  font-size: 0.9rem;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  width: 2.9rem;
  height: 2rem;
  margin: 0 0.5rem;
}

.products label .wrapper .plus,
.products label .wrapper.minus {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
}

.products label svg {
  place-self: center;
  margin-left: 2rem;
  color: rgba(117, 117, 117, 1);
}

.products label .delete-icon {
  cursor: pointer;
}

.outlet .services .greyText {
  font-weight: 500;
  font-size: 16px;
  color: #808080;
  margin-bottom: 1rem;
}

.outlet .services .service {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.outlet .services .package {
  margin-bottom: 2rem;
}

.outlet .services label {
  align-items: center;
  /* height: 5rem; */
}

.services label .delete-icon {
  cursor: pointer;
}

.outlet .services label img {
  width: 6rem;
  height: 6rem;
  object-fit: contain;
  border-radius: 10px;
  margin-right: 1rem;
  cursor: pointer;
}

.outlet .services label .namePrice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.outlet .services label .name {
  width: 16rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.outlet .services label .price {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #757575;
}

.outlet .services label .data {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #878787;
}

ul.data {
  list-style: none;
}

.outlet .services label .logos {
  /* margin-left: auto; */
  /* align-self: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.outlet .services label .logos svg {
  margin-left: 2rem;
  color: rgba(117, 117, 117, 1);
}

.outlet .total {
  background-color: #fdf3ff;
  padding: 24px 12px;
  border-radius: 5px;
  margin: 14px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.outlet .total .total-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #808080;
  text-transform: uppercase;
}

.outlet .total .total-label span {
  color: #ef481e;
  font-size: 24px;
  padding-left: 10px;
}

@media (max-width: 1200px) {
  .outlet .products .product {
    align-items: flex-start;
    position: relative;
    margin-bottom: 3rem;
  }

  .outlet label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .outlet .products label .namePrice {
    width: 75%;
  }
  .products label svg {
    position: absolute;
    right: 0;
  }

  .products label .wrapper {
    display: flex;
    align-items: center;
    width: 6rem;
    margin: 1rem 0;
  }

  /* Services */
  .outlet .services .service {
    align-items: flex-start;
    position: relative;
    margin-bottom: 3rem;
  }

  .outlet .services .package {
    margin-bottom: 3rem;
  }

  .outlet .services label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  /* .outlet .services label .namePrice {
    width: 90%;
  } */

  .services label svg {
    position: absolute;
    right: 0;
    top: 0;
  }

  .services label .heart,
  .products label .heart {
    right: 20%;
  }

  .outlet .services label .logos {
    margin-left: inherit;
    align-self: inherit;
  }

  .service label .namePrice .name {
    margin: 0.2rem 0;
  }

  .service label .namePrice .data {
    margin-bottom: 0.3rem;
    font-size: 13px;
  }

  .service label .logos button {
    margin: 0.3rem 0;
  }

  .outlet .services label {
    height: inherit;
  }
}

@media (max-width: 698px) {
  .outlet .products label .imgPrice {
    flex-direction: column;
  }

  .outlet .products label .imgPrice .namePrice {
    height: inherit;
  }

  .outlet .products label .imgPrice .namePrice .name {
    margin-bottom: 5px;
  }

  .outlet .row p {
    font-size: 15px;
    letter-spacing: -0.5px;
  }
}
@media (max-width: 678px) {
  .outlet .products label .namePrice {
    width: 100%;
  }

  .outlet .services label .name {
    width: 100%;
  }
}
/*--------- Cart ---------*/
.vendible {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.vendible__action button {
  background-color: transparent;
  border: none;
}
.vendible__img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 8px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 173px;
  flex: 0 0 173px;
}
@media (max-width: 768px) {
  .vendible__img {
    width: 75px;
    height: 75px;
  }
}
@media (max-width: 576px) {
  .vendible__img {
    width: 50px;
    height: 50px;
  }
}
.chkvendible__content {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  flex-grow: 2;
  max-width: 100%;
  padding: 0 0 0 10px;
}
@media (max-width: 768px) {
  .chkvendible__content {
    padding: 0 0 0 0;
  }
}
.chkvendible__content ul {
  margin: 0;
  padding: 0px 10px 0px;
  list-style: none;
}
@media (max-width: 992px) {
  .chkvendible__content ul {
    padding: 0px;
  }
}
.chkvendible__content ul li {
  line-height: 30px;
}
@media (max-width: 992px) {
  .chkvendible__content ul li {
    line-height: 28px;
  }
}
@media (max-width: 576px) {
  .chkvendible__content ul li {
    font-size: 14px;
    line-height: 24px;
  }
}
.chkvendible__content .title a,
h2 {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.chkvendible__qty .form-module.form-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px 0 0;
  margin: 0;
}
.chkvendible__qty .form-set {
  position: relative;
  padding: 0 26px;
  margin: 0 0 0 10px;
  width: auto;
  z-index: 0;
}
.chkvendible__qty input[type="text"],
.chkvendible__qty input[type="number"] {
  font: 500 16px/18px "Poppins", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.4em 0.45em;
  height: 30px;
  margin: 0;
  width: 42px;
  text-align: center;
}
.chkvendible__pricing__dis {
  font-weight: 600;
  font-size: 18px;
  color: #757575;
  padding-bottom: 6px;
  word-break: break-word;
}
@media (max-width: 576px) {
  .chkvendible__pricing__dis {
    font-weight: 600;
    font-size: 16px;
    color: #757575;
    padding-bottom: 6px;
  }
}
.chkvendible__pricing__off {
  font-weight: 500;
  font-size: 16px;
  color: #144580;
}
.chkvendible__discount {
  font-weight: 500;
  font-size: 16px;
  color: #808080;
  text-decoration: line-through;
}
.flex__start-express {
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;
  width: 140px;
}
.vendible .delete-icon:hover {
  cursor: pointer;
}

.cart-qty-flex-grow {
  flex-grow: 1;
}

.grayed-data-list {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #878787;
  padding: 0 !important;
}

.grayed-data-list li {
  line-height: 20px !important;
}
