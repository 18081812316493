.no-shadow {
  box-shadow: none !important;
}

.no-padding {
  padding: 0px !important;
}

.accountOutlet {
  max-width: 1000px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
  padding: 2rem;
  border-radius: 7px;
}

.accountOutlet form button {
  margin-left: 0 !important;
  display: block;
  margin-right: 0 !important;
}

@media (max-width: 1400px) {
  /* .accountOutlet {
    width: 775px;
  } */
}

@media (max-width: 1200px) {
  /* .accountOutlet {
    width: 600px;
  } */
}

@media (max-width: 768px) {
  /* .accountOutlet {
    width: 100%;
  } */
}

.accountOutlet .accHeader {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 45px;
  /* identical to box height */
  font-family: "Poppins";
  display: flex;
  align-items: center;

  color: #2f4858;
}

.accountOutlet .welcomeMsg {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #808080;
}

.accountOutlet p strong {
  margin-left: 2px;
}

.accountOutlet form .inputs {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .accountOutlet form .inputs {
    flex-direction: column;
  }
}

.accountOutlet form input {
  margin-right: 1.5rem;
}

.genderDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
}

.genderDiv svg {
  width: 1.1rem;
  margin-right: 10px;
  color: #808080;
}

.genderInput {
  color: #808080;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;
}

.genderSelect {
  width: 24rem;
  height: 45px;
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  background-color: #ffffff;
  border: 2px solid #d6d6d6;
  border-radius: 7px;
  padding-left: 1rem;
  outline: none;
}

.starGender {
  color: #c63535;
}

.genderError {
  color: #db0b0b;
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

@media (max-width: 698px) {
  .accountOutlet {
    max-width: 98vw !important;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
    padding: 1.25rem;
    border-radius: 7px;
  }

  .verticalApp .MuiBox-root {
    width: 100% !important;
    margin: 0 auto;
  }

  .genderDiv {
    width: 100%;
    margin-right: 0px !important;
  }

  .genderSelect {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .genderDiv {
    width: 100%;
    margin-right: 0px !important;
  }

  .genderSelect {
    width: 100% !important;
  }
}

@media (max-width: 400px) {
  .accountOutlet {
    padding: 1rem;
  }
}

@media (max-width: 375px) {
  .accountOutlet {
    max-width: 86vw !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .accountOutlet {
    width: 68vw !important;
  }
}
