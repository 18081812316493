.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  /* Right now, we want to size shimmer according to its parent */
  /* background-size: 800px 104px; */
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;

  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;

  animation-name: placeholderShimmer;
  -webkit-animation-name: placeholderShimmer;

  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.box {
  /* height: 104px;
  width: 100px; */
  height: 100%;
  width: 100%;
}

.shimmer-container {
  width: 100%;
  height: 100%;
}

/* For Shimmer Items */
.shimmer-items-container {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Max height is provided from props. Max height will be the height of shimmer-item. */
  /* max-height: 320px; */
  overflow-y: hidden;
}

.shimmer-items-container .shimmer-item {
  margin: 10px;
}

@media (max-width: 476px) {
  .shimmer-item {
    width: 170px !important;
  }
}

@media (max-width: 410px) {
  .shimmer-item {
    width: 40vw !important;
  }
}

@media (max-width: 360px) {
  .shimmer-item {
    width: 35vw !important;
  }
}
