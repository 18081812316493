.container {
  padding: 1rem 0rem;
}

.headerTitle {
  color: #144580;
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.dataDiv {
  width: 100%;
  position: relative;
  max-height: 25rem;
  height: 25rem;
}

.dataDiv img {
  width: 100%;
  object-fit: cover;
  max-height: 25rem;
  height: 100%;
}

.colorBg {
  position: absolute;
  background-color: #005ec7;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
}

.information {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  transform: translate(-50%, -50%);
}

.info {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: center;
  align-items: center;
}

.info .icon {
  border: 10px solid #ffffff;
  border-radius: 50%;
  width: 6.5rem;
  height: 6.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info svg {
  font-size: 3rem;
}

.info span {
  padding-top: 1rem;
  font-size: 2.2rem;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
}

.info p {
  font-weight: 500;
  font-size: 1.22rem;
  line-height: 30px;
}

@media (max-width: 1200px) {
  .headerTitle {
    margin-bottom: 1rem;
  }
  .dataDiv {
    height: 16rem;
  }

  .info .icon {
    border: 5px solid #ffffff;
    width: 4.5rem;
    height: 4.5rem;
  }

  .info svg {
    font-size: 2.5rem;
  }

  .info span {
    padding-top: 0.6rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 50px;
  }

  .info p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 30px;
  }
}

@media (max-width: 698px) {
  .container {
    padding: 1rem 0rem;
  }

  .headerTitle {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .dataDiv {
    height: 18rem;
  }

  .info {
    width: 50%;
    padding-bottom: 1rem;
  }
  .dataDiv img {
    width: 100%;
    object-fit: cover;
    max-height: 25rem;
    height: 100%;
  }

  .info .icon {
    border: 5px solid #ffffff;
    width: 3.5rem;
    height: 3.5rem;
  }

  .info svg {
    font-size: 2rem;
  }

  .info span {
    padding-top: 0.6rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 20px;
  }

  .info p {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 20px;
  }
}
