/* Info Section */
.info-main {
  color: #878787;
  background-color: #f1f3f6;
  margin: 0;
  padding: 2rem 5rem;
}

.info-main h3 {
  padding-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.info-main .info-data {
  padding-bottom: 2rem;
}

.info-main .info-data h5 {
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.info-main .info-data p {
  font-size: 0.9rem;
}

.info-main .last {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.info-main .last span,
.info-main .last a {
  color: #144580;
  font-weight: 500;
}

hr {
  margin-bottom: 2rem;
}

/* Semi footer */
.semi-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 16px;
}

.semi-footer .box {
  width: 30%;
  display: flex;
}

.semi-footer .box i {
  margin: auto 1.5rem;
  color: #144580;
  font-size: 3.2rem;
}

.semi-footer .box i svg {
  width: 3.2rem;
  color: #144580;
}

.semi-footer .box div h4 {
  font-size: 1.2rem;
  font-weight: 650;
}

.semi-footer .box div p {
  color: #878787;
  font-size: 0.8rem;
}

@media (max-width: 698px) {
  /* Info Section */
  .info-main {
    padding: 2rem 2rem;
  }

  .info-main h3 {
    padding-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 1rem;
  }

  .info-main .info-data {
    padding-bottom: 1.5rem;
  }

  .info-main .info-data p {
    font-size: 0.8rem;
  }

  .info-data .right {
    padding-left: 8px;
  }

  /* Semi Footer */
  .semi-footer .box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .semi-footer .box div {
    text-align: center;
    width: 80%;
    margin: 1rem;
  }
}

/* Footer */
.footer-container {
  max-width: 1170px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.footer ul {
  list-style: none;
}

/* ul {
  list-style: none;
} */

.footer {
  background-color: #f1f3f6;
  padding: 70px 0;
}

.footer-col {
  width: 20%;
  padding: 0 15px;
}

.footer-col-6 {
  width: 50%;
  padding: 0 15px;
}

.footer iframe {
  width: 100%;
  height: 300px;
}

@media (max-width: 992px) {
  .footer-col-6 {
    width: 100%;
  }
}

.footer-col h4 {
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: gainsboro;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 14px;
  text-transform: capitalize;
  color: #878787;
  text-decoration: none;
  font-weight: 400;

  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  /* color: #ffffff; */
  padding-left: 8px;
}

.footer-col .social-links p {
  font-size: 14px;
  text-transform: capitalize;
  color: #878787;
  text-decoration: none;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.footer-col .social-links div {
  margin-bottom: 1rem;
}

.footer-col .social-links .links a {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: -5px 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #878787;
  transition: all 0.3s ease;
}

.footer-col .social-links div a:hover {
  transform: scale(1.1);
  color: #144580;
}

.footer-col .social-links div button {
  padding: 0.6rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  color: #ffffff;
  background-color: #144580;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.4s;
}

.footer-col .social-links div button a {
  color: #ffffff;
}

.footer-col .social-links div button:hover {
  margin-left: 8px;
}

.footer-col .social-links div button:hover a {
  color: #ffffff;
}

.footer-col .app-links {
  margin-top: 8px;
}

.footer-col .app-links li a {
  width: 140px;
  height: 50px;
}

.footer-col .app-links li a img {
  width: 100%;
  height: auto;
}

.footer-col small {
  color: #878787;
}

.divider {
  padding-bottom: 2rem;
  background-color: #f1f3f6;
}

.divider hr {
  margin-bottom: 0;
}

/* Mobile Responsive */
@media (max-width: 698px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
