.add-to-cart {
  background-color: transparent;
  color: #144580;
  border: 2px solid #144580;
  margin-right: 16px;
  width: 10.5rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
}

.add-to-cart-small {
  background-color: transparent;
  color: #144580;
  border: 2px solid #144580;
  margin-right: 8px;
  width: 9rem;
  height: 2.5rem;
  border-radius: 7px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
}

.add-to-cart:hover {
  transform: scale(1.05);
}

.add-to-cart-small:hover {
  transform: scale(1.02);
}
