.filters-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
  /* padding: 0rem 2.5rem; */
}

.filters-section .left {
  width: 25%;
  background-color: #f1f1f1;
  padding: 1rem;
  position: sticky;
  align-self: flex-start;
  top: 150px;
  height: 70vh;
  overflow: auto;
}

/* ::-webkit-scrollbar {
  visibility: hidden;
}

.filters-section .left:hover::-webkit-scrollbar {
  display: block;
} */

.filters-section .left:hover {
  overflow: scroll;
}

/* Chrome, Edge, and Safari */
.filters-section .left::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}
.filters-section .left::-webkit-scrollbar-track {
  background: #fff;
  /* height: 3px; */
}
.filters-section .left::-webkit-scrollbar-thumb {
  background-color: #144580;
  border-radius: 10px;
  /* border: 3px solid #f1f1f1; */
  /* height: 3px; */
}
@media (min-width: 993px) {
  .leftMobile {
    display: none;
  }
}
@media (max-width: 993px) {
  .filters-section .left {
    background-color: transparent;
    position: inherit;
    align-self: flex-start;
    top: 0px;
    height: auto;
    overflow: inherit;
  }

  .leftMobile {
    padding: 0rem 0rem;
  }
}
.left .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #144580;
}

.left .top a {
  color: #144580;
  font-size: 0.8rem;
}

.left .top .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left .top .head svg {
  color: #144580;
  margin-right: 0.4rem;
}

.left .top .head p {
  font-size: 1.1rem;
  font-weight: 500;
}

.left .top small:hover {
  cursor: pointer;
}

/* Category section */
.left .category div {
  padding: 5px 0rem;
  font-size: 1.1rem;
}

.left .category div input {
  margin: 0.4rem;
  margin-right: 1rem;
  accent-color: #144580;
  position: relative;
}

.category div input::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 2px solid #144580;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 0.3s all ease;
}

.category div input:checked::before {
  /* background-color:#144580; */
  border: none;
}

.category div input:checked::after {
  position: absolute;
  content: "\f192";
  font-family: "FontAwesome";
  color: #144580;
  font-size: 1.3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiFormGroup-root {
  display: flex;
  justify-content: flex-start;
}

.MuiFormControl-root {
  padding-left: 0.6rem !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #144580 !important;
}

.PrivateSwitchBase-input {
  display: none;
}

/* .css-ahj2mt-MuiTypography-root {
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  font-family: poppins, "sans-serif" !important;
} */

.category label {
  margin-bottom: 10px;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 5px 9px !important;
}

.range-slider {
  width: 100%;
  /* Match this to the SVG's x2 value */
  margin: auto;
  text-align: center;
  position: relative;
  height: 3.5rem;
}

.MuiSlider-track,
.css-1gv0vcd-MuiSlider-track {
  background-color: #144580 !important;
}

.MuiSlider-rail,
.css-14pt78w-MuiSlider-rail {
  background-color: #c4b9ba !important;
}

.MuiSlider-thumbColorPrimary,
.MuiSlider-thumbSizeMedium,
.MuiSlider-thumb,
.css-eg0mwd-MuiSlider-thumb {
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(20, 69, 128, 1) 37%,
    rgba(255, 255, 255, 1) 62%
  );
  border: 1px solid #144580;
  width: 17px !important;
  height: 17px !important;
}

/* Medicines Section */
/* .medicines .names{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
} */

.medicines .names button {
  padding: 0.3rem 0.8rem;
  border-radius: 5px;
  margin: 0.3rem 0.1rem;
  border: 1px solid #bebebe;
}

/* Right side */
.filters-section .right {
  width: 72%;
}

.right .banner {
  max-width: 100%;
  max-height: 190px;
  overflow: hidden;
}

.right .detailSlider {
  max-height: 250px;
  padding: 0rem;
}

.right .information {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.information h4 {
  font-size: 1.3rem;
  font-weight: 500;
}

.information .sort-view {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-view .sort-by select {
  padding: 0.3rem 0.6rem;
  border: 2px solid #ececec;
  border-radius: 7px;
  font-size: 1rem;
  font-weight: 400;
  color: #111111;
  margin-left: 0.5rem;
  outline: none;
}

/* .sort-view .sort-by select option{
    background-color: #ffffff;
    font-size: 1rem;
} */

.sort-view .view {
  display: flex;
  padding-left: 3rem;
  align-items: center;
}

.sort-view .view p {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 0.5rem;
}

.sort-view .view svg {
  color: #bebebe;
  width: 1.2rem;
  margin-right: 0.3rem;
  cursor: pointer;
}

.view #list {
  font-size: 1.5rem;
  padding-top: 1.5px;
}

.view ion-icon {
  font-size: 1.2rem;
  line-height: 14px;
  margin-left: 0.5rem;
  padding-top: 4px;
  cursor: pointer;
  transition: all 0.1s;
}

.view ion-icon:hover {
  transform: scale(1.05);
}

/* Products */
.filters-section .products {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0px 10px;
}

@media (max-width: 576px) {
  .filters-section .products {
    gap: 15px;
    margin-top: 15px;
  }
}

@media (max-width: 432px) {
  .filters-section .products {
    gap: 12px;
    margin-top: 15px;
  }
}

.details .list-view-pricing {
  display: none;
}

/* Changing to list view */
#list-view {
  width: 100% !important;
  display: flex;
  height: 100%;
}

.list-view .img-div {
  max-height: 161px;
}

.list-view .img-div img {
  height: 10.5rem;
  margin-right: 1rem;
}

.list-view .details h3 {
  font-size: 1.1rem;
  margin-top: 2px;
}

.list-view .details h2 {
  display: flex;
  flex-direction: column;
}

.list-view .details h2 span {
  margin-left: 0;
  display: none;
}

.list-view .details .list-view-pricing {
  display: unset;
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(239, 72, 30);
}

.list-view .details .list-view-pricing span {
  text-decoration: line-through;
  margin: 0 10px;
  color: #757575;
}

.list-view .details .genre-discount .discount {
  display: none;
}

/* end */

.products .cards:hover {
  transform: scale(1.01);
  transition: all 0.2s;
}

.cards .tags {
  position: absolute;
  padding: 2px 7px;
  text-align: center;
  color: #fff;
  right: 2%;
  top: 2.54%;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: 450;
}

.img-div {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.img-div .show-hover {
  position: absolute;
  background-color: #000000;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.5s;
}

.show-hover ion-icon {
  color: black;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-right: 0.6rem;
  padding: 5px;
  border-radius: 50%;
  background-color: #d9d9d9;
  opacity: 1;
  z-index: 3;
  transition: all 0.2s;
  cursor: pointer;
}

.show-hover ion-icon:hover {
  transform: scale(1.08);
}

.new {
  background-color: #fa7250;
}

.featured {
  background-color: #1aab2a;
}

/* Universal */
.filters-section hr {
  margin: 1rem 0rem;
  color: #e2e2e2;
  opacity: 0.5;
}

div h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.filters-section .top-phone {
  display: none;
}

.load-more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-more-btn button {
  background: #144580;
  border: 1px solid #144580;
  color: #fff;
  font-size: 14px;
  border: none;
  font-weight: 500;
  padding: 12px 26px;
  margin-top: 25px;
  border-radius: 6px;
}
.load-more-btn button a {
  color: #fff;
  padding: 12px 26px;
}
.load-more-btn button:hover {
  background: transparent;
  border: 1px solid #144580;
  color: #144580;
  transition: all 0.6;
}
.load-more-btn:hover a {
  color: #144580;
}
/* Responsive */
@media (max-width: 992px) {
  .filters-section {
    padding: 0 1rem;
    position: relative;
  }
  .accordion {
    margin: 1em auto;
    max-width: 100%;
    border: none !important;
  }
  .accordion__button:before {
    display: none !important;
  }
  .accordion__button:after {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    top: 40px;
  }
  .accordion__button[aria-expanded="true"]::after,
  .accordion__button[aria-selected="true"]::after {
    transform: rotate(-135deg);
  }

  .filter-icon {
    padding: 0px 10px 0px 0px;
  }
  .accordion__button {
    background-color: #f6f6f6 !important;
    color: #2c2c2c !important;
    font-weight: 600;
    border: none !important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
    display: flex;
  }
  .filters-section .top-phone {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
  }
  .accordion__panel {
    box-shadow: 0 1px 3px rgb(0 0 0 / 15%) !important;
  }
  .top-phone .head {
    display: flex;
    align-items: center;
    color: #144580;
    font-size: 1rem;
    font-weight: 400;
  }

  .head p {
    padding-left: 4px;
  }

  .top-phone a {
    color: #000000;
  }

  /* Filters left section */
  .filters-section .left {
    z-index: 2;
    width: 100%;
    left: 0;
    padding: 0rem;
    margin-bottom: 15px;
  }
  .leftDesktop {
    display: none;
  }

  /*  */
  .filters-section .right {
    width: 100%;
  }

  .right .information {
    margin: 0rem 0.5rem;
  }

  .right .information h4 {
    font-size: 1rem;
    /* display: none; */
  }

  .sort-by label {
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }

  .sort-by select {
    width: 80%;
  }

  .sort-view .view {
    display: flex;
    padding-left: 3rem;
    padding-top: 0px;
  }

  .products .cards {
    width: 49%;
  }

  .cards .details h2 {
    font-size: 1rem;
  }

  .cards .details h2 span {
    font-size: 0.8rem;
    margin: 0.6rem 0.4rem 0.3rem;
  }

  /* Changing to list view */

  .list-view .img-div img {
    max-height: 148.92px;
    max-width: 199.2px;
    margin-right: 1rem;
  }

  .list-view .details h3 {
    font-size: 1rem;
    margin-top: 3px;
    font-weight: 450;
  }

  .list-view .details h2 span {
    margin-left: 0;
  }

  .list-view .details .genre-discount .discount {
    bottom: 5.5%;
    left: 57%;
  }
}

.width-control-category {
  max-width: 260px;
  width: 100%;
}

@media (min-width: 1400px) {
  .width-control-category {
    max-width: 260px;
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .width-control-category {
    max-width: 225px;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .width-control-category {
    max-width: 215px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .width-control-category {
    max-width: 215px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .width-control-category {
    max-width: 235px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .width-control-category {
    max-width: 500px;
    width: 100%;
  }
}

.headertitle {
  display: flex;
  justify-content: space-between;
  color: gray;
  font-weight: bold;
  margin: 1rem 0;
  border-bottom: 1px solid #e1e0e0;
  /* style={{ color: "gray", fontWeight: "bold", marginTop: "1rem", display:  }} */
}

.headertitle button {
  border: none;
  background: transparent;
  color: rgba(20, 69, 128, 1);
  cursor: pointer;
  padding: 0 10px;
}

.search-products {
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.search-test-products {
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

@media (max-width: 576px) {
  .search-products {
    margin: 0 auto;
  }

  .search-test-products {
    margin: 0 auto;
  }
}
