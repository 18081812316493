.doctorsDiv {
  min-width: 312px;
  height: 135px;
  margin: 0 !important;
  display: flex;
  padding: 1.25rem;
  justify-content: flex-start;
  /* align-items: center; */
  margin-right: 0.3rem;
  border: none;
  column-gap: 0.5rem;
  border: 0.1px solid rgba(136, 165, 191, 0.48);
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-radius: 10px;
}

/* @media (max-width: 1024px) {
  .doctorsDiv {
    width: 280px;
  }
} */

@media (max-width: 768px) {
  .doctorsDiv {
    min-width: 100px;
    padding: 1rem;
    height: 150px;
    /* min-width: 280px; */
  }
}

@media (max-width: 576px) {
  .doctorsDiv {
    flex-direction: column;
    row-gap: 1rem;
    padding: 0.75rem;
    height: 205px;
  }
}

.doctorsDiv:hover {
  box-shadow: none;
  border: 0.1px solid #112e58;
  cursor: pointer;
}

.doctorImg {
  width: 90px;
  height: 90px;
}

@media (max-width: 768px) {
  .doctorImg {
    width: 50px;
    height: 50px;
  }
}

.doctorsDiv img {
  max-width: 38%;
  height: 90px;
  min-width: 85px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .doctorsDiv img {
    max-width: inherit;
    min-width: auto;
    object-fit: contain;
  }
}

.information {
  max-width: 180px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(175, 175, 175);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.information h3 {
  color: #144580;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding-bottom: 4px;
}

.information p {
  font-size: 12px;
  color: #747575;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 3px;
}

.information button {
  margin-top: 6px;
  padding: 0.5rem;
  border: none;
  background-color: #144580;
  color: #ffffff;
  border-radius: 5px;
  font-size: 13px;
  transition: all 0.2s;
  cursor: pointer;
}

.information button:hover {
  transform: scale(1.03);
}
