.healthTestContainer {
  display: flex;
  justify-content: flex-start;
  background-color: aliceblue;
  flex-wrap: wrap;
  padding: 2rem 1rem;
  gap: 1rem;
}

@media (max-width: 768px) {
  .healthTestContainer {
    justify-content: center;
  }
}

.healthTest {
  align-items: center;
  padding: 1rem 0;
}

.testCard {
  width: 170px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
}

.healthTest .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 420px) {
  .testCard {
    width: 135px;
  }
}

@media (max-width: 376px) {
  .testCard {
    width: 120px;
  }
}

@media (max-width: 320px) {
  .testCard {
    width: 110px;
  }
}

/* .searchTestCard {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
} */

@media (max-width: 1024px) {
  .searchTestCard {
    width: 160px !important;
  }
}

@media (max-width: 540px) {
  .searchTestCard {
    width: 140px !important;
  }
}

@media (max-width: 480px) {
  .searchTestCard {
    width: 190px !important;
  }
}

@media (max-width: 425px) {
  .searchTestCard {
    width: 160px !important;
  }
}

@media (max-width: 360px) {
  .searchTestCard {
    /* height: 225px; */
    width: 120px !important;
  }
}

.header h1 {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 2rem;
}

.TestCardSwiper {
  background-color: #fdf3ff;
  padding: 2rem 1rem !important;
}

#flask {
  color: #27548a;
  font-size: 1.1rem;
  padding: 0.9rem 0 0.5rem 0rem;
}

.cardH3 {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  padding: 0rem 0rem;
  text-align: left;
  height: 2.3rem;
  text-transform: capitalize;
  /* word-wrap: break-word; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardP p {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 0.1rem 0rem;
  text-align: left;
  color: #757575;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
}

.price {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ef481e;
  padding: 0.6rem 0rem 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price span {
  font-size: 12px;
  text-decoration: line-through;
  color: #757575;
  font-weight: 400;
  line-height: 16px;
}

.button {
  width: 100%;
  height: 35px;
  border: none;
  border-top: 2px solid #fdf3ff;
  background-color: #ffffff;
  color: #1c4d8c;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  transition: all 0.2s;
}

.button:hover {
  transform: scale(1.05, 1.05);
}

/* Modal */
#flask2 {
  color: #27548a;
  font-size: 1.7rem;
  margin-right: 1rem;
}

@media (max-width: 698px) {
  /* .healthTest {
    padding: 1rem 1rem;
  } */

  .header h1 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 2rem;
  }
}
.paddingCommon {
  padding: 1rem;
}
