.heroContainer {
  padding-bottom: 4rem;
  width: 100%;
}

.heroHeader {
  color: #144580;
  font-size: 1.7rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hero {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.hero .full {
  width: 100%;
}

.hero .left {
  width: 55%;
}

.hero .right {
  width: 45%;
}

.hero ul {
  padding-left: 1.2rem;
  margin-bottom: 1.5rem;
}

.hero ul strong {
  font-weight: 600;
}

.hero h4 {
  color: #ef481e;
  font-size: 1.1rem;
  line-height: 27px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 6px;
}

.hero h3 {
  color: #144580;
  font-style: normal;
  font-size: 2.3rem;
  line-height: 60px;
  font-weight: 500;
}

.hero img {
  width: 100%;
  height: auto;
}

.ulDiv {
  display: flex;
  align-items: center;
}

.ulDiv ul {
  margin: 1rem 1.5rem;
}

.ulDiv ul li {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 25.2px;
  margin-bottom: 10px;
}

.ulDiv ul li::before {
  content: "";
  background-color: #fa7250;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: -1rem;
  margin-right: 1rem;
  border-radius: 50%;
}

.hero p,
.hero li {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-bottom: 1.5rem;
  padding-right: 1.5rem;
}

.hero li {
  line-height: normal;
  margin-bottom: 0.5rem;
}

.hero .left {
  position: relative;
}

.hero .left img {
  width: 22.8rem;
  height: 18.1rem;
  border: 10px solid;
  border-radius: 5px;
}

.hero .left .image1 {
  border-color: #ef481e;
  position: absolute;
  right: 10%;
  top: 3%;
}

.hero .left .image2 {
  border-color: #144580;
  position: absolute;
  top: 25%;
  left: 1%;
}

@media (max-width: 768px) {
  .hero .image1,
  .hero .image2 {
    display: none;
  }

  .hero .left {
    width: 100%;
    /* display: none; */
  }

  .hero .right {
    width: 100%;
    /* padding: 0 1rem; */
  }
}

@media (max-width: 698px) {
  .heroHeader {
    font-size: 1.2rem !important;
    /* padding: 0 1rem; */
    margin-bottom: 1rem !important;
  }

  .hero h4 {
    font-size: 0.9rem;
    line-height: 24px;
    letter-spacing: 0.11em;
  }

  .hero h3 {
    color: #144580;
    font-style: normal;
    font-size: 2.1rem;
    line-height: 55px;
  }

  .ulDiv {
    display: flex;
    align-items: center;
  }

  .ulDiv ul {
    margin: 1rem 1.5rem;
  }

  .ulDiv ul li {
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .ulDiv ul li::before {
    content: "";
    background-color: #fa7250;
    font-weight: bold;
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    margin-left: -1rem;
    margin-right: 1rem;
    border-radius: 50%;
  }

  .hero p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-bottom: 1.5rem;
  }
}
