/* Health Packages */
.health-packages {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  width: 100%;
  min-height: auto;
  max-height: auto;
  background-color: #fdf3ff;
  padding-bottom: 3rem;
}

.test-packages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: auto;
  max-height: auto;
  background-color: #ffffff;
}

.health-packages-header {
  padding: 1rem 0rem;
}

.health-packages-header h2 {
  /* padding-bottom: 2rem; */
  font-size: 1.34rem;
  font-weight: 600;
}

.test-packages .wrapper,
.health-packages .wrapper {
  max-width: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Need to give width especially for when there are only 4 items and slider doesn't have to slide. */
.test-packages .wrapper .swiper-slide,
.health-packages .wrapper .swiper-slide {
  width: 315px !important;
}

.table {
  background: #fff;
  width: 19rem;
  height: 30rem;
  padding: 30px 23px;
  border-radius: 1rem;
  margin-left: 0px;
}

@media (max-width: 1400px) {
  .table {
    margin-left: 25px;
  }
}

.table::before {
  content: " ";
  width: 10rem;
  height: 1rem;
  background-color: #144580;
}

.table .image-section {
  display: flex;
  justify-content: center;
}

.table .image-section img {
  width: 8.5rem;
  height: 7rem;
}

.table .package-name {
  /* display: flex;
  justify-content: center; */
  text-align: center;
  width: 100%;
  margin: 1rem auto;
  font-size: 15px;
  font-weight: 600;
  color: #144580;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .features li {
  margin-bottom: 3px;
  list-style: none;
  display: flex;
  margin-left: 1.3rem;
  overflow: hidden;
}

.table .features li.more {
  text-decoration: underline;
  cursor: pointer;
  color: #144580;
}

.features {
  height: 115px;
}

.features li .list-name {
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 1.2rem;
}

.features li .icon {
  color: #2db94d;
  font-size: 1.1rem;
}

.features li .icon.cross {
  color: #cd3241;
}

.table .price {
  text-align: center;
}

.table .price h1 {
  font-size: 1.3rem;
  color: #ef481e;
  margin: 0.7rem auto;
}

.table .view-more {
  text-align: center;
  color: #144580;
  font-size: 0.8rem;
  margin-top: 8px;
  cursor: pointer;
}

.price h1 span {
  font-size: 1rem;
  text-decoration: line-through;
  color: #757575;
}

.table .btn {
  width: 100%;
  display: flex;
  /* margin-top: 35px; */
  justify-content: center;
}

.btn button {
  width: 100%;
  height: 50px;
  background: #144580;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  border: 2px solid #144580;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 0.5rem;
}
/* .buttons a {
  color: #fff;
} */
.btn button:hover a {
  color: #144580;
}
.btn button a {
  color: #fff;
}

.table .btn button:hover {
  border-radius: 5px;
  background: #fff;
  color: #144580;
}

.wrapper p .show-more-last {
  display: none;
}

@media (max-width: 698px) {
  /* Health Packages Section */
  .health-packages {
    height: auto;
  }

  .test-packages {
    height: auto;
  }

  .wrapper {
    align-items: center;
    justify-content: center;
    max-width: 95%;
  }

  /* .wrapper .swiper-slide-active {
    margin-left: 8%;
} */
  .table {
    margin-bottom: 1.3rem;
    /* width: 70%; */
    height: 30rem;
  }
  /* .health-packages .swiper-slide-active {
    display: flex;
  } */

  .health-packages .header {
    margin: 1rem 1rem;
    place-self: center;
  }

  .header h2 {
    font-size: 1.2rem;
  }

  .health-packages .header p {
    display: none;
  }

  .wrapper p .show-more-last {
    display: contents;
  }

  button:hover {
    background-color: unset;
  }
}

/* Tab View */
@media only screen and (max-width: 1200px) and (min-width: 698px) {
  /* Health Packages Section */
  .health-packages {
    height: auto;
  }

  .wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .wrapper .table {
    flex: 35%;
    margin: 1rem auto;
  }
}

@media (max-width: 650px) {
  .wrapper .swiper-slide-active {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .table {
    margin: 0 auto;
  }
  .search-result {
    width: 12.7rem;
  }
}

@media (max-width: 576px) {
  .table {
    margin: 0 auto;
  }

  .search-result {
    width: 10rem;
  }

  .btn button {
    font-size: 14px;
  }

  .table .price h1 {
    font-size: 1rem;
  }
}

@media (max-width: 360px) {
  .search-result {
    width: 8rem;
  }
}

@media (min-width: 1600px) {
  .table {
    margin-left: 10px;
  }
}
