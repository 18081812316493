/*What we do section */
.what-we-do {
  padding-top: 1rem;
  margin-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.what-we-do .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 1rem; */
  height: 120px;
  width: 180px;
}

@media (max-width: 1400px) {
  .what-we-do .content {
    width: 80px;
  }
}

@media (max-width: 825px) {
  .what-we-do .content {
    width: 60px;
  }
}

/* @media (max-width: 440px) {
  .what-we-do .content {
    width: 60px;
  }
} */

@media (max-width: 340px) {
  .what-we-do .content {
    width: 40px;
  }
}

.what-we-do .box {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.what-we-do .text {
  text-align: center;
  color: black;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 5px 0px;
}

.what-we-do .box .lowerText {
  position: absolute;
  width: 100%;
  color: rgb(19, 161, 161);
  font-size: 1.1rem;
  font-weight: 600;
  bottom: -55%;
  text-align: center;
  /* word-break: break-all; */
  max-width: 150px;
  height: 80px;
}

.what-we-do .box img {
  z-index: 1;
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.what-we-do .box .semi-circle {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 126px;
  width: 100%;
  border-radius: 150px 150px 0 0;
  /* background-color: #ffeee7; */
  z-index: -1;
}

.what-we-do-phone {
  display: none;
}

@media (max-width: 698px) {
  .what-we-do {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.3rem;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    gap: 2rem;
  }

  .what-we-do .box {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  .what-we-do .box img {
    z-index: 1;
    width: 70px;
    height: 70px;
  }

  .what-we-do .box .semi-circle {
    top: 61%;
    height: 90px;
  }

  .what-we-do .box .lowerText {
    position: absolute;
    width: 100%;
    color: #4c6d8c;
    font-size: 0.9rem;
    font-weight: 600;
    bottom: -70%;
  }

  .what-we-do .text {
    /* font-size: 0.95rem; */
    font-size: 0.7rem;
  }
}

@media (max-width: 425px) {
  .what-we-do .box img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 320px) {
  .what-we-do .text {
    font-size: 0.6rem;
  }
}

@media (max-width: 768px) {
  .what-we-do .text {
    font-size: 0.6rem;
  }
}

@media (min-width: 698px) and (max-width: 1200px) {
  .what-we-do {
    padding-top: 1rem;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 2rem;
  }

  .what-we-do .box .lowerText {
    position: absolute;
    width: 100%;
    color: rgb(19, 161, 161);
    font-size: 0.9rem;
    font-weight: 600;
    bottom: -75%;
    text-align: center;
  }

  .what-we-do .box img {
    z-index: 1;
    width: 75px;
    height: 75px;
  }

  .what-we-do .box .semi-circle {
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90px;
    width: 95%;
    border-radius: 150px 150px 0 0;
    /* background-color: #ffeee7; */
    z-index: -1;
  }
}

.what-we-do-cards-collection {
  transition: 0.3s box-shadow ease;
  border: 0.1px solid #fff;
  padding: 5px 10px;
}

.what-we-do-cards-collection:hover {
  border-radius: 10px;
  border: 0.1px solid rgba(136, 165, 191, 0.48);
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
