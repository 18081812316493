.checkout-modal {
  padding-bottom: 0 !important;
}

.modalContent {
  padding: 8px 0px;
}

.modalContent .orders,
.modalContent .address,
.modalContent .finish {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 16px;
  width: 100%;
}

/* Heading */
.modalContent .orders .heading,
.modalContent .address .heading,
.modalContent .finish .heading {
  display: flex;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  color: slategray;
}

.modalContent .orders .heading .title {
  width: 75%;
}

.modalContent .orders .heading .total {
  width: 25%;
}

/* List */
.modalContent .orders .list {
  display: flex;
  margin-bottom: 16px;
}

/* 1st block */
.modalContent .orders .list .image-title {
  width: 50%;
  display: flex;
  align-items: start;
  padding-right: 8px;
}

.modalContent .orders .list .image {
  /* width: 60px;
  height: 60px; */
  width: 25%;
  height: auto;
  padding-right: 12px;
}

.modalContent .orders .list .image img {
  width: 100%;
  height: 100%;
}

.modalContent .orders .list .title-qty {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.modalContent .orders .list .title {
  font-size: 14px;
  margin-bottom: 2px;
  word-wrap: break-word;
}

.modalContent .orders .list .qty {
  font-size: 14px;
  color: gray;
}

/* 2nd and 3rd block */
.modalContent .orders .list .price,
.modalContent .orders .list .total {
  width: 25%;
  padding-right: 8px;
}

.modalContent .orders .list .cost {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
}

.modalContent .orders .list .total .cost {
  color: #ef481e;
}

/* Address */
.modalContent .address .content .title,
.modalContent .address .content .address-desc {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

/* Finish */
.modalContent .finish {
  background-color: #fdf3ff;
  border: 1px solid #fdf3ff;
}

.modalContent .finish .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.modalContent .finish .content .total {
  color: #ef481e;
  font-weight: 600;
}

.modalContent .finish .content .total small {
  font-size: 14px;
  margin-right: 6px;
  font-weight: 500;
}

/* Footer */
.footData small {
  font-size: 14px;
  margin-right: 6px;
}

/* Media Queries */
@media (max-width: 576px) {
  .checkout-modal {
    padding: 1.5rem 1rem;
  }
  .modalContent .orders {
    padding: 8px;
  }
  .modalContent .orders .list .image-title {
    width: 60%;
  }
  .modalContent .orders .list .price {
    width: 20%;
  }
  .modalContent .orders .list .total {
    width: 20%;
  }
  .modalContent .orders .list .image-title .image {
    width: 30%;
    height: auto;
  }
  .modalContent .orders .list .image-title .title-qty {
    width: 70%;
  }
}
