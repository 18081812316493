@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

@media (min-width: 576px) {
  .capsule {
    max-width: 540px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .capsule {
    max-width: 720px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .capsule {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .capsule {
    max-width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 1400px) {
  .capsule {
    max-width: 1320px;
    margin: 0 auto;
  }
}

@media (min-width: 1600px) {
  .capsule {
    max-width: 1520px;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .capsule {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }
}

/* Universal */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 2rem; */
}

.header h2 {
  font-size: 1.34rem;
  font-weight: 600;
}

.header a {
  font-size: 0.84rem;
  color: #144580;
}

section {
  margin: 2rem 0;
  padding: 1rem 0;
}

.crossed {
  text-decoration: line-through;
}

a.view-all {
  font-size: 0.84rem;
  color: #144580;
}

a.view-all:hover {
  color: #ef481e;
}

.link-secondary {
  color: #ef481e;
}

.width-full {
  width: 100%;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}
