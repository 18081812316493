.not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
}

.not-found .img {
  width: 280px;
}

.not-found img {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}
