/* Info Header */
.info-header {
  background-color: #134580;
  width: 100vw;
  color: white;
  padding-top: 4px;
  letter-spacing: 0.5px;
  top: 0;
}

.info-header p {
  font-size: 12px;
  font-weight: 500;
}

.info-header .content {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 576px) {
  .info-header .content {
    justify-content: center;
  }
}

.info-header .content .info {
  display: flex;
  padding-right: 24px;
}

.info-header .content .info i {
  padding-right: 4px;
}

.info-header .content .info a {
  color: #fff;
}

@media screen and (max-width: 576px) {
  .info-header .content .top-email {
    display: none;
  }
  .info-header .top-description {
    display: none;
  }
}

/* Navbar */
.nav {
  /* padding: 1rem 1rem 0.7rem; */
  top: 0px;
  position: sticky;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(31, 38, 135, 0.37);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

/* Upeer nav */
.nav .upper-nav {
  display: flex;
  padding: 14px 8px;
}

/* .nav .upper-nav .logo-div {
  margin-left: 2rem;
} */

.nav .upper-nav .right-side {
  display: flex;
  margin-left: auto;
  justify-content: space-around;
}

.upper-nav .right-side .search-form {
  width: 37.5rem;
  height: 2.8rem;
  background-color: #f5f5f6;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  margin-right: 3rem;
}

.upper-nav .right-side .search-form input {
  height: 98%;
  width: 98%;
  background-color: #f5f5f6;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  outline: none;
}

.upper-nav .right-side .search-form input:active {
  border: none;
}

.upper-nav .right-side .search-form button {
  border: none;
  display: flex;
}

.upper-nav .right-side .search-button {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.upper-nav .right-side .search-form label {
  font-size: 1.1rem;
  /* padding-left: 0.6rem; */
  /* padding-right: 1rem; */
  /* padding-top: 0.9rem; */
  cursor: pointer;
  color: #ef481e;
}

.search-form label:hover {
  transform: scale(1.08);
  transition: all 1s;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.icons a {
  border: none;
  background-color: transparent;
  color: black;
  font-size: 1.3rem;
  margin-top: 0.4rem;
  margin-right: 3rem;
  position: relative;
  cursor: pointer;
}

.icons a i {
  width: 1.3rem;
  color: black;
}

.icons a svg {
  width: 1.3rem;
  color: black;
}

.icons a ion-icon {
  font-size: 1.5rem;
}

.icons a i:hover,
.icons a ion-icon:hover {
  transform: scale(1.05);
  transition: all 0.3s;
}

.icons a::before {
  position: absolute;
  top: 100%;
  left: -30%;
  font-size: 0.7rem;
}

.icons .profile::before {
  content: "Profile";
}

.icons .login::before {
  content: "Login";
}

.icons .wishlist::before {
  content: "Wishlist";
}

.icons .cart::before {
  content: "Cart";
  left: 0%;
}

/* .icons .cart::after {
  content: ".";
  position: absolute;
  font-size: 3.5rem;
  color: #ef481e;
  top: -185%;
  left: 60%;
} */

/* lower nav */
.nav .lower-nav {
  padding: 8px 0px;
  position: relative;
}

.nav .lower-nav ul {
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
  gap: 1rem;
}

.nav .lower-nav ul li {
  font-size: 0.87rem;
  font-weight: 550;
  position: relative;
  padding: 0 10px;
  transition: all 0.3s;
}

.nav .lower-nav ul li a {
  color: black;
  font-size: 0.77rem;
  font-weight: 550;
}

.nav .lower-nav ul li:before {
  content: " ";
  display: block;
  position: absolute;
  width: 0%;
  height: 3px;
  bottom: -40%;
  left: -0%;
  border-radius: 3px;
  background-color: #ef481e;
  transition: all 0.2s;
}

@media (max-width: 576px) {
  .nav .lower-nav ul li:before {
    content: " ";
    bottom: 0%;
  }
}

.nav .lower-nav ul li:hover:before {
  width: 100%;
}

.active::before {
  width: 100% !important;
}

.nav .upper-nav .open-nav-logo,
.nav .upper-nav .mb-search-bar,
.nav .lower-nav .close-nav-logo {
  display: none;
}

/* Mega Menu Section */
/* Medicines */
.medicine {
  position: absolute;
  display: flex;
  /* padding: 0.5rem 2.5rem; */
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  flex-wrap: wrap;
  width: 95%;
  top: 101%;
  left: 200%;
  min-height: 50vh;
  max-height: 100%;
  transition: all 0.3s;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.medicine .col {
  padding-top: 2rem;
  text-align: left;
  width: 15rem;
  padding-left: 2rem;
}

.medicine .col:nth-of-type(2),
.medicine .col:nth-of-type(4) {
  background-color: #f9f9f9;
}

.medicine .col span {
  color: #ef481e;
  font-weight: 500;
}

.medicine .col p {
  padding-bottom: 10px;
  cursor: pointer;
  transition: all 0.1s;
  color: #585858;
}

.medicine .col p:hover {
  transform: scale(1.05);
  padding-left: 0.3rem;
}

.loginModal {
  width: 1000px;
}

@media (max-width: 698px) {
  /* Universal */
  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 0.8rem;
  }

  .header h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  /* .header a {
    display: none;
  } */

  section {
    margin: 1rem 0;
    padding: 1rem 0;
  }

  /* Navbar */
  .nav .upper-nav {
    justify-content: space-between;
    position: relative;
  }

  .nav .upper-nav .right-side .search-form {
    width: 50vw;
    position: absolute;
    left: -100%;
    bottom: -200%;
    z-index: 1042;
    margin-right: 0;
    transition: all 0.4s;
  }

  .upper-nav .right-side .search-form label {
    color: #3f4e4f;
  }

  .icons {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .icons a {
    margin-right: 0rem;
  }

  .icons .cart,
  .icons .wishlist {
    margin-right: 1.5rem;
  }

  .nav .upper-nav .logo-div {
    /* margin-left: 2rem; */
    padding-top: 0.4rem;
    align-items: center;
  }

  .nav .upper-nav .logo-div .logo {
    width: 11rem;
  }

  .nav .upper-nav .open-nav-logo {
    padding: 0px 8px;
  }

  .nav .upper-nav .open-nav-logo,
  .nav .upper-nav .mb-search-bar {
    font-size: 1.4rem;
    padding-top: 0.6rem;
    display: unset;
    cursor: pointer;
  }

  /* This section is the sliding  nav in mobile responsive */
  .nav .lower-nav {
    background-color: #002b5b;
    width: 80%;
    position: absolute;
    z-index: 1041;
    height: auto;
    min-height: 100vh;
    top: 0;
    left: -100%;
    transition: all 0.4s;
    border-radius: 10px;
  }

  .nav .lower-nav .close-nav-logo {
    display: block;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
    padding-left: 1rem;
  }

  .nav .lower-nav ul {
    flex-direction: column;
    align-items: left;
    margin-top: 6rem;
    margin-right: 12%;
    padding-left: 2rem;
  }

  .nav .lower-nav ul li {
    position: relative;
    line-height: 1.3rem;
    font-size: 1rem;
    padding-right: 10%;
    color: #fff;
    text-align: left;
    font-weight: 400;
    letter-spacing: 1px;
    border: 1px solid #2b4865;
    border-right: none;
    border-left: none;
    padding: 0.8rem;
  }

  .nav .lower-nav ul li a {
    color: #fff;
    font-weight: 400;
  }

  .nav .lower-nav ul .active ::before {
    height: 0px;
  }

  .nav .lower-nav ul li:hover:before {
    width: 0%;
  }

  /* .nav .lower-nav ul li::before {
        content: " ";
        position: absolute;
        background-color: white;
        width: 113%;
        height: 1px;
        left: 0;
        top: -1rem;
        opacity: 0.2;
    } */

  .nav .lower-nav ul .medicines::before {
    background-color: white;
    height: 1px;
  }

  .nav .lower-nav ul .appointments::after {
    content: " ";
    position: absolute;
    background-color: white;
    width: 113%;
    height: 1px;
    left: 0;
    bottom: -1.4rem;
    opacity: 0.2;
  }

  .medicine {
    display: none;
  }

  .active::before {
    width: 0;
  }

  .loginModal {
    width: 98vw;
  }
}

@media (max-width: 576px) {
  .nav .lower-nav .close-nav-logo {
    padding-left: 0.8rem;
  }
}

/* Tab View */
@media only screen and (max-width: 1200px) and (min-width: 698px) {
  /* Navbar */
  .nav .upper-nav {
    justify-content: space-between;
    position: relative;
  }

  .nav .upper-nav .right-side .search-form {
    width: 40%;
    position: absolute;
    left: -100%;
    bottom: -140%;
    z-index: 1042;
    margin-right: 0;
    transition: all 0.4s;
    top: 30px !important;
  }

  .upper-nav .right-side .search-form label {
    color: #3f4e4f;
  }

  .icons {
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .icons a {
    margin-right: 0rem;
  }

  .icons .cart {
    margin-right: 1.5rem;
  }

  .icons .wishlist {
    margin-right: 1.5rem;
  }

  .nav .upper-nav .logo-div {
    /* margin-left: 33vw; */
    margin-left: 1rem;
    width: 100%;
    align-items: center;
  }

  .nav .upper-nav .logo-div .logo {
    width: 13.5rem;
  }

  .nav .upper-nav .open-nav-logo,
  .nav .upper-nav .mb-search-bar {
    font-size: 1.4rem;
    padding-top: 0.8rem;
    display: unset;
    cursor: pointer;
  }

  .nav .lower-nav {
    background-color: #144580;
    width: 60%;
    position: absolute;
    z-index: 1041;
    height: auto;
    min-height: 100vh;
    top: 0;
    left: -100%;
    transition: all 0.4s;
  }

  .nav .lower-nav .close-nav-logo {
    display: unset;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    margin-left: 1.5rem;
    margin-top: 1rem;
  }

  .nav .lower-nav ul {
    flex-direction: column;
    align-items: right;
    margin-top: 8rem;
    margin-right: 12%;
  }

  .nav .lower-nav ul li {
    margin-bottom: 2.5rem;
    position: relative;
    line-height: 1.3rem;
    font-size: 1.05rem;
    padding-right: 10%;
    color: #fff;
    text-align: right;
    font-weight: 430;
    letter-spacing: 1px;
  }

  .nav .lower-nav ul li a {
    color: #fff;
  }

  .nav .lower-nav ul .active ::before {
    height: 0px;
  }

  .nav .lower-nav ul li::before {
    content: " ";
    position: absolute;
    background-color: white;
    width: 113%;
    height: 1px;
    left: 0;
    top: -1rem;
    opacity: 0.2;
  }

  .nav .lower-nav ul .medicines::before {
    background-color: white;
    height: 1px;
  }

  .nav .lower-nav ul .appointments::after {
    content: " ";
    position: absolute;
    background-color: white;
    width: 113%;
    height: 1px;
    left: 0;
    bottom: -1.4rem;
    opacity: 0.2;
  }

  .medicine {
    display: none;
  }

  .active::before {
    width: 0;
  }

  .loginModal {
    width: 98vw;
  }
}

@media only screen and (max-width: 400px) {
  .nav .upper-nav .logo-div {
    width: 100%;
    display: block;
  }
  .nav .upper-nav .logo-div .logo img {
    width: 100%;
    margin-left: 1rem;
  }
}

@media (max-width: 320px) {
  .nav .upper-nav .logo-div .logo {
    width: 8rem;
  }
}

@media screen and (max-width: 1200px) {
  .nav .lower-nav ul li.active a {
    color: hsl(12deg 87% 60%);
  }
  .nav .lower-nav ul li:before {
    background-color: transparent !important;
  }
}

/* Backdrop */
.nav-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.6;
}
