.appointment {
  height: auto;
  width: 100%;
  display: flex;
  padding-bottom: 2rem;
  justify-content: space-between;
}

header {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .appointment {
    flex-direction: column-reverse;
  }
}

section {
  margin-top: 0rem;
}

.main .mainHeader {
  color: #0d0d0d;
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin-bottom: 5px;
}

.main .mainP {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  color: #808080;
  margin-bottom: 30px;
}

.form {
  width: 55%;
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 2.5rem; */
  gap: 0px 30px;
}

@media (max-width: 1600px) {
  .form {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .form {
    margin-top: 0;
  }
}

.button:hover {
  transform: scale(1.03);
}

.doctorCard {
  width: 25%;
  height: fit-content;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .doctorCard {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .doctorCard {
    width: 100%;
  }
}

.doctorCard header {
  width: 100%;
  background-color: #144580;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doctorCard .doctorCardHeader h1 {
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 30px;
}

.imgDiv {
  display: flex;
  justify-content: center;
}

.doctorImage {
  width: 8.5rem;
  height: 9.5rem;
  margin: 1rem 0;
}

.doctorCard .info {
  padding: 1rem 1.5rem;
}

.info p {
  color: #808080;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}

/* .info p span {
  color: #ef481e;
  padding-right: 4px;
} */

.info span p {
  margin-bottom: 0px;
}

.info span {
  display: inline-block;
}

.info span:first-child {
  color: #ef481e;
  padding-right: 4px;
}

.star {
  color: #c63535;
}

.label {
  color: #808080;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;
}

.select {
  width: 24rem;
  height: 45px;
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  background-color: #ffffff;
  border: 2px solid #d6d6d6;
  border-radius: 7px;
  padding-left: 1rem;
  outline: none;
}

.inputLabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
}

.inputLabel svg {
  width: 1.1rem;
  margin-right: 10px;
  color: #808080;
}

.messageBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
}

.messageBox svg {
  width: 1.1rem;
  margin-right: 10px;
  color: #808080;
}

.messageBox textarea {
  border: 2px solid #d6d6d6;
  outline: none;
  resize: none;
  border-radius: 5px;
  height: 8rem;
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  padding: 0.7rem;
}

.rightSide {
  width: 37%;
  margin-top: 0;
}

.rightSide img {
  width: 460px;
  height: 411px;
}

.rightSide .companyInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  margin-top: 2rem;
}

.companyInfo div {
  border: 2px solid #ef481e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
}

.companyInfo p {
  color: #808080;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.companyInfo .contactLink {
  color: #808080;
}

.companyInfo .contactLink:hover {
  color: #144580;
}

.companyInfo svg {
  color: #ef481e;
}

.socialMediaIcons {
  color: #ffffff;
  display: flex;
  margin-left: 2rem;
}

.socialMediaIcons a {
  color: inherit;
  background-color: #144580;
  margin-right: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
}

.formError {
  color: #db0b0b;
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

@media (max-width: 698px) {
  .appointment {
    flex-direction: column-reverse;
    padding: 0rem 2rem;
  }

  .appointment form {
    margin-top: 1rem;
  }

  .main {
    padding: 0rem;
  }

  header {
    padding: 1rem 1.5rem;
  }

  .main .mainHeader {
    font-size: 1.4rem;
  }

  .main .mainP {
    font-size: 1rem;
    font-weight: 500;
  }

  .select {
    width: 20rem;
  }

  button:nth-of-type(1) {
    margin-left: unset;
  }

  .buttons {
    align-items: flex-start;
    width: 100%;
  }

  .doctorCard {
    width: 100%;
    height: fit-content;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    margin: 1rem auto;
    overflow: hidden;
  }

  .form {
    width: 100%;
  }

  .select {
    width: 100%;
  }

  .inputLabel {
    width: 100%;
  }

  .input {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .rightSide {
    position: relative;
    display: none;
  }

  .rightSide img {
    display: none;
  }

  /* .appointment {
    padding-top: 2rem;
  } */

  .appointment .form {
    width: 75%;
    padding-right: 2rem;
  }

  .select {
    width: 100%;
  }

  .inputLabel {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .appointment .form {
    margin: 0rem auto;
    width: 100% !important;
    padding: 0;
  }
}

@media (max-width: 576px) {
  .appointment {
    padding: 0;
  }

  .appointment .form {
    margin: 0 auto;
    width: 95% !important;
    /* margin-top: 2rem; */
  }

  .doctorCard {
    margin: 0 auto;
    width: 95% !important;
  }

  header {
    padding: 1rem;
    padding-bottom: 0;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
  }
}
