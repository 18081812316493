.side-menu-gear {
  /* For Floating */
  /* position: sticky !important;
  top: 130px;
  right: 20px;
  text-align: right;
  float: right; */
  z-index: 5;
  padding: 0 !important;
  max-width: 25px;
  min-width: 25px !important;
  max-height: 25px;
  width: 100% !important;
}

@media (min-width: 1000px) {
  .side-menu-gear {
    display: none !important;
  }
}

/* For Floating */
/* @media (max-width: 520px) {
  .side-menu-gear {
    top: 150px;
  }
} */

.side-menu-gear svg {
  width: 30px;
  height: 30px;
}

.menu-list-item {
  display: flex;
  column-gap: 0.8rem;
  color: #144580 !important;
  border-radius: 10px !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 0.9rem !important;
  line-height: 24px !important;
  padding: 0 inherit !important;
}

.menu-list-item svg {
  width: 18px;
  height: 18px;
}
