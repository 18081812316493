.position-relative {
  position: relative;
}

.search-drop {
  width: 37.5rem;
}

@media (max-width: 1200px) {
  .search-drop-container {
    display: none;
  }
}

@media (max-width: 1200px) {
  .display-mobile {
    display: none;
  }
}

.search-items {
  font-size: 14px;
  color: rgb(107, 107, 107);
  font-family: "Poppins", sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
}

.search-products-title {
  font-weight: 500;
  background-color: #f7f7f7;
  padding: 0.5rem;
  color: rgb(14, 12, 12);
  font-size: 14px;
}

.search-services-title {
  font-weight: 500;
  background-color: #f7f7f7;
  padding: 0.5rem;
  color: rgb(14, 12, 12);
  font-size: 14px;
}

.search-services-section {
  margin-top: 1rem;
}

.search-img {
  width: 45px;
  height: 40px;
  margin-right: 12px;
}

@media only screen and (max-width: 1200px) and (min-width: 698px) {
  .search-drop {
    width: 40%;
  }
}

@media (max-width: 698px) {
  .search-drop {
    width: 50vw;
  }
}

/* .css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}

@media (max-width: 1200px) {
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    display: none;
  }
} */
