.mySwiper {
  width: 100%;
  height: 100%;
}
.sliderBox img {
  display: block;
  width: 100%;
  /* max-height: 46vh; */
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 1400px) {
  .sliderBox img {
    height: auto;
  }
}

/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ef481e;
  height: 0.6rem;
  width: 1.6rem;
  border-radius: 10px;
}

span.swiper-pagination-bullet {
  background-color: #000;
  opacity: 1;
  height: 0.6rem;
  width: 0.6rem;
}

@media (max-width: 698px) {
  /* Homepage */
  .slide1 img {
    height: 100%;
  }
}
