.verticalApp .MuiButtonBase-root {
  text-align: left;
  justify-content: flex-start;
  min-height: 3.4rem;
  margin-right: 2rem;
  min-width: 16rem;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 24px;
  color: #144580;
}

@media (max-width: 992px) {
  .verticalApp .MuiButtonBase-root {
    min-width: 6rem;
    margin: 0;
  }
}

@media (max-width: 576px) {
  .verticalApp .MuiButtonBase-root {
    min-width: 3rem;
    margin: 0;
  }
}

.verticalApp {
  overflow-x: hidden;
}

.verticalApp .MuiTabs-flexContainer .Mui-selected {
  background: #144580;
  color: #fff;
}

.breadCrumbs {
  width: auto;
}

.css-j204z7-MuiFormControlLabel-root {
  display: none;
  appearance: none;
}

.tabArrows {
  width: 1.5rem;
  height: 4rem;
  background-color: #c8cdd3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.tabArrowRight {
  /* border-bottom-right-radius: 8px;
    border-top-right-radius: 8px; */
  border-radius: 4px;
  top: 3rem;
  z-index: 10;
  box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 15%);
}

.tabArrowLeft {
  /* border-bottom-right-radius: 8px;
  border-top-right-radius: 8px; */
  border-radius: 4px;
  top: 3rem;
  left: 0;
}

.tab-panel-content {
  padding: 8px 2px;
}

@media (max-width: 1000px) {
  .leftTab {
    display: none;
  }
}

@media (min-width: 1000px) {
  .tab-panel-content {
    padding: 16px;
  }
}
