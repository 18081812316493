.cards {
  border: 1px solid #dadce0;
  border-radius: 0.3rem;
  /* width: auto; */
  width: 220px;
  height: 18rem;
  margin: 1rem 0rem;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s;
}

.cards:hover {
  box-shadow: rgba(136, 165, 191, 0.48) 2px 2px 16px 2px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

@media (max-width: 1200px) {
  .cards {
    /* width: 13.7rem; */
    width: 12.7rem;
  }
}

/* @media (max-width: 992px) {
  .cards {
    width: 13.7rem;
  }
} */

@media (max-width: 768px) {
  .cards {
    height: 20rem;
  }
}

@media (max-width: 576px) {
  .cards {
    height: 19rem;
  }
}

.cards .imgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  padding-top: 10px;
}

@media (max-width: 1200px) {
  .cards .imgDiv {
    /* width: 16rem; */
    width: 100%;
  }
}

@media (max-width: 992px) {
  .cards .imgDiv {
    width: 100%;
    height: 175px;
  }
}

.cards .imgDiv img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.cards .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
}

.cards .details {
  height: 40%;
  padding-bottom: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 576px) {
  .cards .details {
    height: 35%;
    padding-bottom: 0.5rem;
  }
}

.cards .details .genreDiscount {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0.6rem 0rem;
}

.cards .details .genreDiscount .genre {
  font-size: 0.75rem;
  color: #747575;
}

.cards .details .genreDiscount .discount {
  color: #ef481e;
  font-size: 0.87rem;
  font-weight: 600;
}

.cards .details h3 {
  font-size: 0.87rem;
  text-align: left;
  padding: 0.5rem 0.6rem 0rem;
  font-weight: 500;
}

.cards .details h2 {
  font-size: 1.12rem;
  text-align: left;
  margin: 0.6rem 0.6rem 0.3rem;
  font-weight: 600;
  color: #ef481e;
}
@media (max-width: 576px) {
  .cards .details h2 {
    margin: 0rem !important;
  }
}
.cards .details h2 span {
  font-size: 0.75rem;
  text-align: left;
  margin: 0.6rem 0rem 0.3rem;
  font-weight: 600;
  color: #757575;
}

#show-hover ion-icon {
  display: none;
}

.details .listViewPricing {
  display: none;
}

/* Changing to list view */
#list-view {
  width: 100% !important;
  display: flex;
  height: 100%;
}

#list-view .img-div {
  max-height: 161px;
  overflow: hidden;
}

#list-view .img-div img {
  height: 10.5rem;
  margin-right: 1rem;
}

#list-view .details h3 {
  font-size: 1.1rem;
  margin-top: 2px;
}

#list-view .details h2 {
  display: flex;
  flex-direction: column;
}

#list-view .details h2 span {
  margin-left: 0;
  display: none;
}

#list-view .details .listViewPricing {
  display: block !important;
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(239, 72, 30);
}

#list-view .details .listViewPricing span {
  text-decoration: line-through;
  margin: 0 10px;
  color: #757575;
}

#list-view .details .genreDiscount .discount {
  display: none;
}

/* end */

.cards .tags {
  position: absolute;
  padding: 2px 7px;
  text-align: center;
  color: #fff;
  right: 2%;
  top: 2.54%;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: 450;
}

.imgDiv {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.imgDiv .showHover {
  position: absolute;
  background-color: #000000;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.5s;
}

.showHover ion-icon {
  color: black;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-right: 0.6rem;
  padding: 5px;
  border-radius: 50%;
  background-color: #d9d9d9;
  opacity: 1;
  z-index: 3;
  transition: all 0.2s;
  cursor: pointer;
}

.showHover ion-icon:hover {
  transform: scale(1.08);
}

.new {
  background-color: #fa7250;
}

.featured {
  background-color: #1aab2a;
}

@media (max-width: 768px) {
  .cards {
    /* width: 15rem; */
    width: 12.7rem;
  }

  .cards .imgDiv {
    /* width: 14rem; */
    width: 100%;
    height: 190px;
  }

  .cards .details .genreDiscount {
    display: flex;
    justify-content: space-between;
    margin: 0.4rem 0.6rem 0rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .cards .details .genreDiscount .genre {
    font-size: 0.75rem;
    color: #747575;
  }

  .cards .details .genreDiscount .discount {
    color: #ef481e;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .cards .details h3 {
    font-size: 0.85rem;
    text-align: left;
    padding: 0rem 0.6rem 0rem;
    font-weight: 500;
    margin: 0;
  }

  .cards .details h2 {
    font-size: 1rem;
    text-align: left;
    margin: 0rem 0.6rem 0rem;
    font-weight: 600;
    color: #ef481e;
  }

  @media (max-width: 768px) and (min-width: 576px) {
    .cards .details h2 {
      margin: 0;
    }
  }

  .cards .details h2 span {
    font-size: 0.7rem;
    text-align: left;
    margin: 0.3rem 0.3rem 0.3rem;
    font-weight: 600;
    color: #757575;
  }
  /* Changing to list view */

  #list-view .img-div img {
    max-height: 148.92px;
    max-width: 199.2px;
    margin-right: 1rem;
  }

  #list-view .details h3 {
    font-size: 0.9rem;
    margin-top: 0px;
    font-weight: 500;
  }

  #list-view .details h2 span {
    margin-left: 0;
  }

  #list-view .details .genre-discount .discount {
    bottom: 5.5%;
    left: 57%;
  }
}

@media (max-width: 400px) {
  .cards {
    width: 11.5rem;
  }

  .cards .details h3 {
    font-size: 0.83rem;
    margin: 0;
  }

  .cards .details h2 {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .cards {
    /* width: 85%; */
    width: 12rem;
    margin: 0 auto;
  }

  .cards .imgDiv {
    width: 100%;
  }
}

@media (max-width: 432px) {
  .cards {
    width: 10rem;
  }
}

@media (max-width: 360px) {
  .cards {
    width: 8rem;
  }
}

.cards .crossed {
  text-decoration: line-through;
}

@media (max-width: 576px) {
  .cards .crossed {
    display: block;
    padding-left: 0.3rem;
  }
}
