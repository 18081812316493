.container {
  background-color: #eef3ff;
  padding: 2rem 0rem;
  margin-bottom: 4rem;
}

.headerTitle {
  color: #144580;
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.videos iframe {
  border-radius: 10px;
}

.video1Name {
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.sideVideos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 29.475rem;
  flex-wrap: wrap;
}

.video2,
.video3 {
  position: relative;
}

.sideVideoName {
  position: absolute;
  bottom: 0;
  background-color: #000000;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  width: 100%;
  height: 1.9rem;
  opacity: 0.8;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
}

@media (max-width: 1200px) {
  .container {
    background-color: #eef3ff;
    /* padding: 2rem 1rem; */
    margin: 1rem 0rem;
  }
  .video1 {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .video1 iframe {
    width: 100%;
    height: 20rem;
  }

  .sideVideos {
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  .sideVideos div {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .sideVideos iframe {
    width: 95%;
    position: relative;
  }

  .sideVideoName {
    position: absolute;
    bottom: 0.37rem;
    background-color: #000000;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    width: 95%;
    height: 1.9rem;
    opacity: 0.8;
    border-radius: 0px 0px 6px 6px;
    display: flex;
    align-items: center;
    padding-left: 0.8rem;
  }
}

@media (max-width: 698px) {
  .container {
    background-color: #eef3ff;
    /* padding: 1.5rem 1rem; */
    margin: 0rem 0rem;
  }
  .headerTitle {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  /* .sideVideos {
    display: none;
  } */
  .sideVideos div {
    width: 100%;
    margin-bottom: 1rem;
  }
  .video1 iframe {
    width: 100%;
    height: 13rem;
  }

  .video1Name {
    font-weight: 500;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  .video1 {
    margin-bottom: 0.5rem;
  }
}
