.faqAccordion {
  width: 75%;
  padding: 0rem 0rem 3rem 0rem;
}
.faqAccordion .Mui-expanded .MuiButtonBase-root {
  background-color: #ffffff !important;
}

.faqAccordion .header {
  padding-bottom: 2rem;
}

.faqAccordion .header h2 {
  font-weight: 600;
  font-size: 1.65rem;
  padding-bottom: 1rem;
}

.faqAccordion .header p {
  color: #808080;
  font-weight: 500;
  font-size: 0.93rem;
}

.faqAccordion .rows {
  padding: 0.6rem 0rem;
  border: 1px solid #ececec;
  margin-bottom: 0.8rem;
  -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.faqAccordion .rows::before {
  height: 0px !important;
}

.faqAccordion .topic {
  color: #3d3a3a;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
}

.faqAccordion .answers {
  color: #808080;
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justified;
}

.faqAccordion .answers strong {
  font-weight: 600;
}

.faqAccordion .answers ul {
  padding-top: 6px;
  padding-left: 16px;
}

.faqAccordion .answers ul li {
  font-size: 14px;
  font-weight: 400;
}

.faqAccordion .footer {
  padding-top: 2rem;
}

.faqAccordion .footer h2 {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 36px;
  color: #ef481e;
}

.faqAccordion .footer p {
  color: #808080;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 698px) {
  .faqAccordion {
    width: 90%;
    padding: 3rem 0rem 3rem 1.3rem;
  }

  .faqAccordion .header h2 {
    font-weight: 600;
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }

  .faqAccordion .header p {
    color: #808080;
    font-weight: 500;
    font-size: 0.83rem;
  }
}
