.display-flex {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2.5rem 3rem;
  column-gap: 0.5rem;
}

.photo-container {
  width: 100%;
  overflow: hidden;
  transition: transform 0.5s ease;
}

.photo-container img {
  transition: transform 0.8s ease;
}

/* .photo-container:hover img {
  transform: scale(1.2);
} */

/* .photo-container:hover {
  transform: scale(1.1);
} */

.ad-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  /* padding: 0px 8px; */
  /* border: 1px solid transparent; */
  border-radius: 6px;
  overflow: hidden;
}

.ad-card:hover .photo-container img {
  transform: scale(1.2);
}

.offer-box {
  background-color: #144580;
  height: 140px;
  width: 100%;
  position: relative;
  color: white;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.offer-box .main-offer {
  padding-left: 6px;
  background-color: inherit;
}

.offer-box .tag {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.offer-box .offer-content-box {
  margin-left: 0px;
  background-color: inherit;
  z-index: 0;
  position: relative;
}

.offer-box .offer-content {
  border: 3px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  z-index: 0;
  background-color: inherit;
  height: 70px;
  width: 70px;
}

@media (min-width: 386px) {
  .offer-box .offer-content-box {
    margin-left: 6px;
  }

  .offer-box .offer-content {
    height: 90px;
    width: 90px;
  }

  .offer-box .main-offer {
    padding-left: 12px;
    background-color: inherit;
  }

  .offer-box .offer-content .number {
    font-size: 60px;
  }

  /* .m-offer-box .m-main-offer .number {
    font-size: 30px;
  } */
}

.offer-box .offer-content::before {
  content: "";
  background-color: inherit;
  position: absolute;
  top: 14px;
  bottom: 12px;
  left: -3px;
  right: -3px;
  z-index: -1;
}

.offer-box .offer-content .number {
  font-size: 38px;
  font-weight: 600;
}

@media (min-width: 386px) {
  .offer-box .offer-content .number {
    font-size: 60px;
  }
}

.offer-box .offer-content .discount {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 6px;
}

.offer-box .discount .percent {
  font-size: 18px;
}

@media (min-width: 386px) {
  .offer-box .discount .percent {
    font-size: 30px;
  }
}

.offer-box .discount .off {
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 400;
  padding-bottom: 4px;
}

@media (min-width: 386px) {
  .offer-box .discount .off {
    font-size: 15px;
  }
}

.offer-box .offer-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.offer-box .offer-details .heading {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 6px;
  width: 100%;
  overflow: hidden;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}

.offer-box .offer-details button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 9px 12px;
  margin-bottom: 4px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
}

.offer-box .offer-details button:hover {
  background-color: white;
  color: #144580;
}

@media (max-width: 1600px) {
  .display-flex {
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .display-flex {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .display-flex {
    padding: 1.3rem;
  }
}

.image-width {
  width: 100%;
  /* height: 240px; */
  overflow: hidden;
  /* margin-right: 16px; */
}

@media (max-width: 1440px) {
  .image-width {
    /* width: 50%; */
    width: 100%;
  }
}

/* @media (max-width: 992px) {
  .image-width {
    width: 49%;
  }
} */

@media (max-width: 576px) {
  .image-width {
    width: 80%;
  }
  .offer-box {
    width: 80%;
  }
}

.image {
  width: 100%;
  height: 100%;
}

/* Mobile List View */
.m-ad-card {
  display: none;
}

@media (max-width: 992px) {
  .ad-card {
    display: none;
  }
  .m-ad-card {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 6px;
    overflow: hidden;
  }
  .m-ad-card .m-photo-container {
    width: 60%;
  }
  .m-ad-card .m-offer-box {
    width: 50%;
    background-color: #144580;
    position: relative;
    color: white;
    padding: 12px;
    display: flex;
    justify-content: space-between;
  }
  .m-offer-box .m-main-offer {
    padding-left: 6px;
    background-color: inherit;
  }
  .m-offer-box .m-main-offer .tag {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  .m-main-offer .offer-content-box {
    margin-left: 0px;
    background-color: inherit;
    z-index: 0;
    position: relative;
    padding-top: 10px;
  }
  .m-main-offer .offer-content {
    border: 3px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;
    z-index: 0;
    background-color: inherit;
    height: 80px;
    width: 80px;
  }
  .m-main-offer .offer-content .number {
    font-size: 38px;
    font-weight: 500;
  }
  .m-main-offer .offer-content .discount {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 6px;
  }
  .m-main-offer .discount .percent {
    font-size: 18px;
  }
  .offer-box .discount .off {
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 400;
    padding-bottom: 4px;
  }
  .m-main-offer .offer-content::before {
    content: "";
    background-color: inherit;
    position: absolute;
    width: 120%;
    top: 14px;
    bottom: 12px;
    left: -6px;
    right: -6px;
    z-index: -1;
  }
  .m-offer-box .m-offer-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .m-offer-details .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-top: 6px;
    width: 100%;
    overflow: hidden;
  }
  .m-offer-details button {
    background-color: transparent;
    color: white;
    border: 2px solid white;
    padding: 9px 12px;
    margin-bottom: 4px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
  }

  .m-offer-details button:hover {
    background-color: white;
    color: #144580;
  }
}

@media (max-width: 768px) {
  .m-offer-details .heading {
    font-size: 18px;
  }
  .m-offer-details button {
    padding: 6px 8px;
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .m-offer-details .heading {
    font-size: 16px;
  }
}

@media (max-width: 426px) {
  .m-main-offer .offer-content {
    width: 60px;
    height: 60px;
  }
  .m-main-offer .offer-content-box {
    padding-top: 0px;
  }
  .m-main-offer .offer-content .number {
    font-size: 24px;
  }
  .m-main-offer .discount .percent {
    font-size: 12px;
  }
  .offer-box .discount .off {
    font-size: 6px;
  }
  .m-offer-details .heading {
    font-size: 12px;
  }
  .m-offer-details button {
    padding: 4px 6px;
    font-size: 8px;
  }
}

@media (max-width: 325px) {
  .m-offer-details .heading {
    word-break: break-word;
  }
  .m-ad-card .m-photo-container {
    width: 45%;
  }
  .m-ad-card .m-offer-box {
    width: 55%;
  }
}
