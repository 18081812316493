.loading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-text {
  font-size: 14px;
  color: rgb(107, 107, 107);
  font-family: "Poppins", sans-serif;
  margin-top: 8px;
}

.loader {
  width: 20px;
  height: 20px;
  /* margin: 0px 12px; */
  border: 3px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
