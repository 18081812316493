.mainBox {
  padding: 0rem 0rem 3rem 0rem;
}
.css-old1by p {
  margin-top: 30px;
}
.mainTab {
  align-content: flex-start;
  display: flex;
}

.title {
  font-size: 1rem !important;
  font-weight: 600 !important;
  font-family: "poppins", sans-serif !important;
  font-family: Poppins !important;
  line-height: 27px !important;
  letter-spacing: -0.015em !important;
  text-align: left !important;
  color: #144580 !important;
}

.serviceHead {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 1rem;
}

.rightUl,
.wrongUl {
  padding: 2rem 0;
}

.rightUl li i,
.wrongUl li i {
  font-size: 1.3rem;
  margin-right: 1rem;
}

.rightUl li,
.wrongUl li {
  margin-bottom: 0.5rem;
  color: #808080;
}

.rightUl li i {
  color: #33b747;
}

.wrongUl li i {
  color: #ef1923;
}

@media (max-width: 698px) {
  .mainBox {
    padding: 0rem 1rem;
  }

  .title {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    font-family: "poppins", sans-serif !important;
    line-height: 27px !important;
    letter-spacing: -0.015em !important;
    text-align: left !important;
    color: #144580 !important;
  }
}
@media (max-width: 992px) {
  .widthFull {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 576px) {
  .mainBox {
    padding: 0rem 0rem;
  }
}
