/* .MuiPopover-root {
  pointer-events: none;
} */

.cart-menu-container {
  padding: 1rem !important;
  width: 300px;
}

@media (min-width: 375px) {
  .cart-menu-container {
    width: 320px;
  }
}

.cart-scroll {
  height: 350px;
  overflow-y: auto;
}

.menu-item {
  cursor: default !important;
  padding: 0.8rem 0 !important;
}
.cart-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 100%;
}

.cart-img-section {
  display: flex;
  width: 25%;
  cursor: pointer;
}

.cart-text-section {
  width: 60%;
}

.cart-text-title {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.cart-btn-section {
  width: 15%;
}

.cart-btn {
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

.cart-btn:hover {
  color: rgb(239, 72, 30);
}

.cart-title {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.01em;
  font-family: "Poppins", sans-serif;
  white-space: break-spaces;
  color: inherit;
  cursor: pointer;
}

.cart-title:hover {
  color: rgb(239, 72, 30);
}

.cart-delete {
  height: 20px !important;
  width: 20px !important;
}

.cart-price {
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.cart-total-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-total-title h4 {
  font-weight: 500;
}

.cart-underline {
  border-bottom: 1px solid #ccc;
  margin: 0.8rem 0;
}

.cart-go-btn {
  margin: 0 !important;
}

.cart-remove-btn {
  margin: 0 !important;
}

.cart-btn-collection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  margin-top: 1.5rem;
}
