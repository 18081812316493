.buy-now {
  background-color: #144580;
  color: #ffffff;
  border: none;
  width: 10.5rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
}

.buy-now-small {
  background-color: #144580;
  color: #ffffff;
  border: none;
  width: 9rem;
  height: 2.5rem;
  border-radius: 7px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
}

.buy-now:hover {
  background-color: transparent;
  color: #144580;
  border: 1px solid #bebebe;
  transform: scale(1.05);
}

.buy-now-small:hover {
  background-color: transparent;
  color: #144580;
  border: 1px solid #bebebe;
  transform: scale(1.02);
}
