/* Health Packages */
.healthPackages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 40rem;
  max-height: auto;
  background-color: #ffffff;
}

.wrapper {
  max-width: 95%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.table {
  background: #fff;
  width: 100%;
  height: 22rem;
  padding: 30px 23px;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 1rem;
  border: 1px solid #dadce0;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .table {
    width: 85%;
    margin: 0 auto;
  }
}

.table::before {
  content: " ";
  width: 10rem;
  height: 1rem;
  background-color: #144580;
}

.table .imageSection {
  display: flex;
  justify-content: flex-start;
}

.table .imageSection img {
  height: 25px;
  width: 26.3px;
}

.table .packageName {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 1rem auto;
  font-size: 1.05rem;
  font-weight: 600;
  line-height: 24px;
  color: #0d0d0d;
  overflow-x: hidden;
  white-space: nowrap;
}

.table .features li {
  margin-bottom: 3px;
  list-style: none;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.table .features li.more {
  text-decoration: underline;
  cursor: pointer;
  color: #144580;
}

.features {
  height: 115px;
}

.features li .listName {
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 1.2rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.features li .icon {
  color: #2db94d;
  font-size: 1.1rem;
}

.features li .icon.cross {
  color: #cd3241;
}
.table p {
  text-align: center;
}

.table p a {
  text-decoration: none;
  font-size: 0.8rem;
}

.table .price {
  text-align: left;
}

.table .price h1 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #ef481e;
  margin: 0.7rem auto;
}

.price h1 span {
  font-size: 0.9rem;
  text-decoration: line-through;
  color: #757575;
  font-weight: 400;
  line-height: 16px;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.buttons button {
  font-size: 1rem !important;
  width: 120px;
  height: 40px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;
}

.whiteButton {
  color: #144580;
  background-color: white;
  border: 2px solid #144580;
}

.blueButton {
  color: #ffffff;
  background-color: #144580;
  border: 2px solid #144580;
}

.buttons button:hover {
  transform: scale(1.02);
}

.wrapper p .show-more-last {
  display: none;
}
/* Modal */
#flask2 {
  color: #27548a;
  font-size: 1.7rem;
  margin-right: 1rem;
}

@media (max-width: 698px) {
  /* Health Packages Section */
  .health-packages {
    height: auto;
  }

  .wrapper {
    align-items: center;
    justify-content: space-around;
    max-width: 95%;
  }
  /* .wrapper .swiper-slide-active {
    margin-left: 8%;
} */
  .table {
    margin-bottom: 1.3rem;
    width: 85%;
  }

  .healthPackages .header {
    margin: 1rem 1rem;
    place-self: center;
  }

  .healthPackages .header h2 {
    text-align: center;
  }

  .healthPackages .header p {
    display: none;
  }

  .wrapper p .show-more-last {
    display: contents;
  }

  button:hover {
    background-color: unset;
  }
}

/* Tab View */
@media only screen and (max-width: 1200px) and (min-width: 698px) {
  /* Health Packages Section */
  .healthPackages {
    height: auto;
  }

  .wrapper {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .wrapper .table {
    flex: 35%;
    margin: 1rem;
  }
}

@media (max-width: 650px) {
  .wrapper .swiper-slide-active {
    margin-left: 5%;
  }
}
