.ordersOutlet {
  width: 960px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
  padding: 2rem;
  border-radius: 7px;
}

.ordersOutlet .MuiTabScrollButton-horizontal {
  min-width: auto !important;
}

@media (max-width: 1400px) {
  .ordersOutlet {
    width: 775px;
  }
  .ordersOutlet .MuiTab-root {
    min-width: 10rem !important;
  }
}

@media (max-width: 525px) {
  .ordersOutlet .MuiTab-root {
    min-width: 3rem !important;
  }
}

@media (max-width: 1200px) {
  .ordersOutlet {
    width: 600px;
  }
}

@media (max-width: 768px) {
  .ordersOutlet {
    padding: 2rem;
  }
}

@media (max-width: 400px) {
  .ordersOutlet {
    padding: 1rem;
  }
}

.ordersOutlet .accHeader {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 45px;
  /* identical to box height */
  font-family: "Poppins";
  display: flex;
  align-items: center;

  color: #2f4858;
}

.ordersOutlet .subH {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #144580;
}

.ordersOutlet p strong {
  margin-left: 2px;
}

.ordersOutlet .products .product {
  display: flex;
  border: 2px solid rgba(218, 220, 224, 1);
  padding: 0.9rem;
  border-radius: 7px;
  align-items: center;
  margin-bottom: 1rem;
}

.ordersOutlet .products .test-service {
  justify-content: space-between;
}

@media (min-width: 998px) {
  .ordersOutlet .products .test-service .price-discount .price,
  .ordersOutlet .products .test-service .price-discount .discount {
    text-align: right;
  }
}

.ordersOutlet .products .product .img-name {
  display: flex;
  align-items: center;
}

.ordersOutlet .products .product .img-name img {
  margin-right: 1rem !important;
}

.ordersOutlet .products .product .img-name .name-genre {
  width: auto;
}

.ordersOutlet .products .product .img-name .name-genre .name {
  word-break: break-word;
}

.products .product img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 7px;
  margin-right: 2rem;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .products .product img {
    width: 4rem;
    height: 4rem;
    margin-right: 0rem;
  }
}

.product img {
  width: 20%;
}

.services .subject img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 7px;
  margin-right: 2rem;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .services .subject img {
    width: 4rem;
    height: 4rem;
    margin-right: 0rem;
  }
}

.subject img {
  width: 20%;
}

.product .name-genre {
  margin-right: 1rem;
  width: 35%;
}

@media (max-width: 992px) {
  .product .name-genre {
    width: 100%;
  }
}

.product .price-discount {
  /* margin-right: 3.5rem; */
  width: 20%;
}

@media (max-width: 992px) {
  .product .price-discount {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .product .price-discount {
    width: 40%;
  }
}

@media (max-width: 576px) {
  .product .price-discount {
    width: 100%;
  }
}

.product .order-report {
  width: 25%;
}

@media (max-width: 992px) {
  .product .order-report {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .product .order-report {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .product .order-report {
    width: 100%;
  }
}

.name-genre .name {
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
  /* height: 2.7rem; */
  margin-bottom: 8px;
  cursor: pointer;
}

.name-genre p {
  font-weight: 500;
  font-size: 0.8rem;
  color: rgba(128, 128, 128, 1);
}

.price-discount .price {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #ef481e;
  word-break: break-word;
}

.price-discount .discount {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.015em;
  color: #ef481e;
  word-break: break-word;
}

.price-discount .discount span {
  text-decoration: line-through;
  color: #4b4848;
}

.product .order-report .outcome {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.product .order-report .outcome i {
  margin-right: 0.4rem;
}

.product .order-report .outcome i.pending {
  color: #144580;
}

.product .order-report .outcome i.delivered {
  color: #26a541;
}

.product .order-report .outcome i.shipped {
  color: #fcb90e;
}

.product .order-report .outcome i.cancelled {
  color: #b9262d;
}

.product .order-report .remarks {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 21px;
  color: #808080;
}

/* --Tabs-- */
.tabs .MuiTabs-flexContainer .Mui-selected {
  background: none;
  color: #144580 !important;
}

.tabs .MuiButtonBase-root {
  text-transform: capitalize;
  color: #808080 !important;
}

/* --Tabs-- */

@media (max-width: 1000px) {
  .ordersOutlet {
    width: 100%;
  }

  .ordersOutlet .products .product {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
  }

  .ordersOutlet .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .products .product img {
    margin-bottom: 1rem;
  }

  .product .name-genre {
    margin-right: 3rem;
  }

  .product .price-discount {
    margin-bottom: 1rem;
  }

  @media (max-width: 576px) {
    .product .price-discount {
      margin-bottom: 0.5rem;
    }
  }

  .price-discount .price {
    margin-bottom: 4px;
  }
}

@media (max-width: 1000px) {
  .product .name-genre {
    margin-right: 0.6rem;
  }
}

@media (max-width: 992px) {
  .ordersOutlet .products .product {
    width: 48%;
  }

  .myorder-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .ordersOutlet .products .product {
    width: 100%;
  }
}
