/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(175, 175, 175);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mainDiv {
  width: 100%;
  display: flex;
  /* padding: 0rem 2.4rem; */
  padding-bottom: 2rem;
  justify-content: space-between;
}

.css-jvho9e-MuiPaper-root-MuiAccordion-root {
  margin-bottom: 0.8rem;
  border-radius: 5px !important;
  border: none;
  box-shadow: 0px 4px 14px 0px #00000026 !important;
}

.accordionDiv {
  width: 68%;
}

.accordionDiv .accordionHeader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.accordionHeader svg {
  color: #144580;
  font-size: 1.83rem;
  margin-left: 0.7rem;
  margin-right: 1rem;
}

.accordionHeader h4 {
  font-size: 1rem;
  color: #808080;
  font-weight: 500;
  letter-spacing: 0.6px;
}

.accordionInputLabel {
  display: flex;
  align-items: flex-start;
  padding-top: 1rem;
  padding-left: 0.5rem;
  flex-basis: 100%;
  margin-bottom: 1.2rem;
  width: 80%;
}

@media (max-width: 720px) {
  .accordionInputLabel {
    width: 100%;
    flex-direction: column;
    row-gap: 1rem;
  }
}

@media (max-width: 720px) {
  .paymentAccordion {
    flex-direction: row !important;
  }
}

.accordionInputLabel input {
  accent-color: #ef481e;
  width: 1rem;
  height: 1rem;
  margin-top: 5px;
  margin-right: 1rem;
}

.accordionInputLabel label.address-wrapper {
  width: 70%;
}

.addressDiv .personalInfo {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

@media (max-width: 992px) {
  .addressDiv .personalInfo {
    align-items: end;
    column-gap: 1rem;
  }
}

@media (max-width: 576px) {
  .addressDiv .personalInfo {
    flex-direction: column;
    align-items: flex-start;
  }
}

.personalInfo .name {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000000;
  font-family: poppins, "sans-serif";
  word-break: break-word;
}

.addressDiv .default {
  color: #144580;
  font-weight: bold;
}

.labelP {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000000;
  font-family: poppins, "sans-serif";
}

.personalInfo .name .number {
  margin-left: 0.4rem;
}

.personalInfo .edit {
  color: #ef481e;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.personalInfo .delete {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addressDiv .location {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #808080;
  padding-bottom: 0.5rem;
}

.addressDiv .addNew {
  display: flex;
  align-items: center;
  color: #ef481e;
  margin-top: 1.3rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.addressDiv .addNew svg {
  margin-right: 0.5rem;
}

.add-new {
  padding-left: 0.4rem;
  display: flex;
  justify-content: space-between;
}

.add-new p {
  display: flex;
  align-items: center;
  color: #ef481e;
  margin-top: 1.3rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.add-new p svg {
  margin-right: 0.5rem;
}

.mainDiv .Mui-expanded .MuiButtonBase-root {
  background-color: #144580 !important;
  transform: all 0.2s;
}

.mainDiv .Mui-expanded .accordionHeader svg {
  color: #ffffff;
}

.mainDiv .Mui-expanded .accordionHeader h4 {
  color: #ffffff;
}

.mainDiv .Mui-expanded .MuiAccordionSummary-expandIconWrapper {
  color: #ffffff !important;
}

/* Order Summary */
.cart-qty {
  margin: 0 auto !important;
}

.productsServices {
  padding: 1rem;
  /* overflow: auto; */
}

@media (max-width: 576px) {
  .productsServices {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.productsServices .titleDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.titleDiv p {
  color: #808080;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
}

@media (max-width: 576px) {
  .titleDiv .total {
    display: none;
  }
}

.productsServices .productRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}

/* @media (max-width: 768px) {
  .productsServices .productRow {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  }
} */
@media (max-width: 576px) {
  .productsServices .productRow {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}

.productRow i {
  margin: auto 0;
  font-size: 1.3rem;
  color: #808080;
  cursor: pointer;
  padding-right: 8px;
}

.orderedProductCross {
  display: flex;
}

.productRow .orderedProductDetails {
  display: flex;
  align-items: center;
  width: 60%;
}

@media (max-width: 576px) {
  .productRow .orderedProductDetails {
    width: inherit;
  }
}

.productRow .orderedProductDetails img {
  /* width: 6.5rem;
  height: 4.5rem; */
  width: 20%;
  height: auto;
  object-fit: cover;
  margin-right: 1rem;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.orderedProductDetails .nameQuantity {
  /* width: 100%; */
  width: 75%;
}

@media (max-width: 576px) {
  .productRow .orderedProductDetails img {
    width: 25%;
  }

  .orderedProductDetails .nameQuantity {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    /* width: 100%;  */
  }
}

/* @media (max-width: 990px) {
  .orderedProductDetails .nameQuantity {
    width: 8rem;
  }
} */

.orderedProductDetails .nameQuantity .productName {
  font-size: 1rem;
  color: #000000;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

@media (max-width: 992) {
  .orderedProductDetails .nameQuantity .productName {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .orderedProductDetails .nameQuantity .productName {
    width: 100%;
  }
}

.orderedProductDetails .nameQuantity .quantityWrapper {
  display: flex;
  align-items: center;
}

.nameQuantity .quantityWrapper p {
  font-size: 0.9rem;
  color: #000000;
  font-weight: 500;
  line-height: 1.5rem;
  margin-right: 1rem;
}

.nameQuantity .quantityWrapper .num {
  border: 2px solid #bebebe;
  border-radius: 5px;
  font-size: 0.9rem;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  width: 3rem;
  margin: 0 0.9rem;
}

.nameQuantity .quantityWrapper .wrapper {
  display: flex;
  align-items: center;
}

.quantityWrapper .wrapper input {
  width: 25%;
  height: 36px;
}

@media (max-width: 576px) {
  .quantityWrapper .wrapper input {
    width: 100%;
  }
}

.plus,
.minus {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
}

.productRow .priceAndDiscount {
  width: 12rem;
  /* padding-left: 2rem; */
}

@media (max-width: 990px) {
  .productRow .priceAndDiscount {
    width: 6rem;
  }
}

.productRow .totalPrice {
  /* margin-left: auto; */
}

.productRow .totalPrice .totalPrice-wrap {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  align-items: flex-start;
  margin-top: 15px;
}

@media (min-width: 577.1px) {
  .productRow .totalPrice .totalPrice-wrap .totalPrice-wrap-total {
    display: none;
  }
}

@media (max-width: 576px) {
  .productRow .totalPrice .totalPrice-wrap .totalPrice-wrap-total {
    display: block;
  }
}

.productRow .priceAndDiscount h3 {
  color: #757575;
  font-weight: 500;
  font-size: 1.1rem;
}

@media (max-width: 992px) {
  .productRow .priceAndDiscount h3 {
    color: #757575;
    font-weight: 500;
    font-size: 15px;
  }
}

.productRow .priceAndDiscount h3 span {
  color: #757575;
  font-weight: 400;
  font-size: 0.8rem;
  text-decoration-line: line-through;
  /* margin-left: 0.5rem; */
  white-space: nowrap;
}

.productRow .priceAndDiscount p {
  color: #ef481e;
  margin-top: 0.7rem;
  font-weight: 600;
  font-size: 0.9rem;
}

.productRow .totalPrice h3 {
  color: #ef481e;
  text-align: right;
}

/* Serices Section */
.mainDiv .services {
  color: #808080;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.mainDiv .services-container {
  border: 1.5px solid #bebebe;
  padding: 1rem;
  border-radius: 5px;
  overflow: auto;
}

.mainDiv .servicesRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  /* border: 1.5px solid #bebebe;
  padding: 1rem;
  border-radius: 5px; */
}

.servicesRow .namePrice {
  width: 16rem;
}

@media (max-width: 990px) {
  .servicesRow .namePrice {
    width: 8rem;
  }
}

.servicesRow .leftSide {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.servicesRow .leftSide i {
  color: #27548a;
  font-size: 1.3rem;
  margin-right: 1.5rem;
}

.servicesRow .leftSide img {
  /* width: 64px; */
  /* margin-right: 1.5rem; */
  width: 6rem;
  height: 4rem;
  object-fit: cover;
  margin-right: 1.4rem;
  border-radius: 5px;
  cursor: pointer;
}

.servicesRow .leftSide .namePrice h4 {
  /* color: #0d0d0d; */
  font-weight: 500;
  font-size: 1.05rem;
  margin-bottom: 0.3rem;
  word-wrap: break-word;
}

.servicesRow .leftSide .namePrice p {
  color: #878787;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.servicesRow .leftSide .namePrice h3 {
  color: #ef481e;
  font-weight: 600;
  font-size: 1rem;
}

.servicesRow .rightSide {
  display: flex;
  align-items: center;
}

.servicesRow .rightSide p {
  color: #d40404;
  font-weight: 400;
  font-size: 0.88rem;
  margin: 0 0.5rem;
}

.servicesRow .rightSide .remove {
  cursor: pointer;
}

.khalti {
  display: flex;
  align-items: center;
}

.khalti img {
  width: 2rem;
  margin-right: 0.7rem;
}

.payment {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  overflow: auto;
}

.payment button {
  height: 3rem;
}

/* Details Div */
.detailsDiv {
  width: 29%;
  height: fit-content;
  position: sticky;
  top: 145px;
}

@media (max-width: 1190px) {
  .detailsDiv {
    top: 95px;
  }
}

.detailsDiv .promoCode {
  background-color: #f2f2f2;
  width: 100%;
  padding: 1rem;
  border-radius: 7px;
  margin-bottom: 0.4rem;
}

.detailsDiv .promoCode h4 {
  font-weight: 400;
  font-size: 1.1rem;
  color: #5e5d59;
  position: relative;
  margin-bottom: 1.6rem;
}

.detailsDiv .promoCode h4::after {
  content: "";
  position: absolute;
  width: 110%;
  height: 2px;
  background-color: #ffffff;
  left: -5%;
  bottom: -0.8rem;
}

.detailsDiv .promoCode .promoCodeInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.7rem;
}

.promoCodeInput input {
  padding: 0.5rem;
  width: 13rem;
  border: 1px solid #bebebe;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
}

.promoCodeInput button {
  margin: 0;
  background-color: #bebebe !important;
}

.promoCode p {
  color: #ef481e;
  font-size: 0.9rem;
  font-weight: 400;
}

.detailsDiv .paymentInfo {
  background-color: #f2f2f2;
  width: 100%;
  padding: 1rem;
  border-radius: 7px;
}

.detailsDiv .paymentInfo h4 {
  font-weight: 400;
  font-size: 1.1rem;
  color: #5e5d59;
  position: relative;
  margin-bottom: 1.6rem;
}

.detailsDiv .paymentInfo h4::after {
  content: "";
  position: absolute;
  width: 110%;
  height: 2px;
  background-color: #ffffff;
  left: -5%;
  bottom: -0.8rem;
}

.detailsDiv .paymentInfo .details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}

.paymentInfo .details p {
  font-weight: 500;
  font-size: 0.9rem;
}

.paymentInfo .details p span {
  color: #d40404;
  font-size: 0.65rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.paymentInfo .dashedBorder {
  width: 100%;
  height: 1px;
  border: 1px dashed #bdbdbd;
  margin: 1.1rem 0;
}

.paymentInfo .totalAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentInfo .totalAmount h3 {
  font-weight: 400;
  font-size: 1.1rem;
}

@media (min-width: 769px) and (max-width: 1199.98px) {
  .detailsDiv .paymentInfo .details,
  .paymentInfo .totalAmount {
    flex-direction: column;
    align-items: flex-start;
  }
}

.paymentInfo .totalAmount h2 {
  font-weight: 600;
  font-size: 1.2rem;
}

.paymentInfo .remarks {
  color: #ef481e;
  font-weight: 400;
  font-size: 0.85rem;
  margin-top: 1.2rem;
  position: relative;
}

.paymentInfo .remarks::after {
  content: "";
  position: absolute;
  width: 110%;
  height: 2px;
  background-color: #ffffff;
  left: -5%;
  top: -0.7rem;
}

.finalText {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0rem 1rem;
}

.finalText p {
  color: #808080;
  font-weight: 400;
  font-size: 0.9rem;
}

.finalText i {
  color: #808080;
  margin-right: 0.3rem;
}

/* Modal Add new */
.modalBox h4 {
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.modalBox .checkmark {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.modalBox .checkmark input {
  margin-right: 6px;
}

.modalBox .checkmark i {
  color: #ef481e;
  font-size: 1rem;
  margin-right: 0.9rem;
}

.modalBox .checkmark p {
  font-weight: 400;
  font-size: 0.9rem;
  color: #808080;
}

.modalBox .buttonGroup {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
}

.modalBox .buttonGroup button {
  width: 8rem;
}

.modalBox .buttonGroup button:hover {
  transform: scale(1.03);
}

/* addNewAddressModal */
.addNewAddressModal .billingHeader {
  display: flex;
  align-items: center;
}

.addNewAddressModal .billingHeader h4 {
  font-weight: 400;
  font-size: 1.3rem;
}

.addNewAddressModal .billingHeader span {
  color: #bebebe;
  font-size: 1.8rem;
  margin: 0 1rem;
  font-weight: 300;
}

.addNewAddressModal .billingHeader p {
  color: #ef481e;
  font-weight: 400;
  font-size: 1rem;
}

.inputFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addNewAddressModal .addressDetailsDiv {
  display: flex;
  justify-content: space-between;
}

.addressDetailsDiv .col .head {
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
  margin-top: 1.45rem;
  margin-bottom: 1rem;
}

.col .data {
  height: 6rem;
}

.fullName .inputLabel input {
  accent-color: #ef481e;
  margin-right: 1rem;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

.fullName .inputLabel label {
  font-size: 1rem;
  font-weight: 400;
}

.fullName .inputLabel label .default {
  font-size: 0.7rem;
  color: #ef481e;
  font-weight: 500;
}

.fullName .head {
  margin-left: 2rem;
}

.addressDetailsDiv .phoneNumber {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.addressDetailsDiv .address .addressEdit p {
  color: #808080;
  font-weight: 400;
  font-size: 1rem;
  outline: none;
}

.addressDetailsDiv .address .addressEdit i {
  color: #999999;
  cursor: pointer;
}

.addressEdit {
  display: flex;
  align-items: flex-start;
}

.address {
  width: 18rem;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .mainDiv {
    flex-direction: column;
    padding: 2rem 1.4rem;
    justify-content: center;
  }

  .accordionDiv {
    width: 100%;
  }

  .detailsDiv {
    width: 100%;
  }
}

@media (max-width: 698px) {
  /* .mainDiv {
    flex-direction: column;
    padding: 2rem 1.4rem;
    justify-content: center;
  } */

  .accordionDiv {
    width: 100%;
  }

  .detailsDiv {
    width: 100%;
    margin: 1.5rem 0rem;
  }

  .accordionHeader svg {
    font-size: 1.73rem;
  }

  .accordionHeader h4 {
    font-size: 1rem;
  }

  .personalInfo .name {
    font-size: 0.9rem;
  }

  .personalInfo .name .number {
    margin-left: 0.1rem;
  }

  .addressDiv .location {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.2rem;
    padding-bottom: 0.33rem;
  }

  .addressDiv .addNew {
    font-size: 0.8rem;
  }

  .labelP {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .productsServices {
    padding: 1rem 0.5rem;
  }

  .productRow i {
    margin: auto 0;
    font-size: 1.1rem;
  }

  .productRow .orderedProductDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .priceAndDiscount {
    display: none;
  }

  .servicesRow {
    display: flex;
  }

  .payment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .payment form {
    margin-bottom: 1rem;
  }
}

.addressLabel {
  width: 50%;
}

@media (max-width: 576px) {
  .addressLabel {
    width: 100%;
  }
}

.addressEditDiv {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding-left: 8px;
}

@media (min-width: 1200px) {
  .max-width-total-price {
    max-width: 105px;
    width: 100%;
  }
}

.checkout-vendible--img {
  width: 4.5rem !important;
  height: 4.5rem !important;
  object-fit: cover !important;
  margin-left: 5px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}
