.main-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0rem 0.5rem 1rem;
  gap: 3rem;
}

.main-section .picture {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.picture .thumbnail {
  width: 100%;
  height: 28rem;
  margin-bottom: 2rem;
  border: 2px solid #bebebe;
  border-radius: 5px;
}

.picture .thumbnail .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.picture .thumb {
  width: 100%;
  height: 7rem;
  /* margin-bottom: 2rem; */
}
.picture .thumb .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.picture .thumb .swiper-slide-thumb-active {
  border: 1px solid #bebebe;
}

/*
 .picture .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.picture .mySwiper2 .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 539px;
    max-height: 488px;
    object-fit: cover;
}

.picture .mySwiper2 .swiper-wrapper{
}

.picture .mySwiperThumb .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 61px;
    max-height: 63px;
    object-fit: cover;
}

.picture .swiper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.picture .swiper-slide {
    background-size: cover;
    background-position: center;
    border: 1px solid #c5b7b7;
}

.picture .mySwiper2 {
    height: 75%;
    width: 90% !important;
}

.picture .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.picture .mySwiper .swiper-slide {
    width: 25%;
    height: 80%;
    opacity: 0.4;
    border: 1px solid #c5b7b7;
    margin-top: 1rem;
}

.picture .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}



:root {
    --swiper-navigation-color: #22135a !important;
    opacity: 1 !important;
    --swiper-navigation-size: 1rem !important;
}

.picture .swiper-button-next,
.picture .swiper-button-prev {
    background-color: transparent;
    box-shadow: none;
    border-radius: 50%;
    padding: 1rem;
    font-weight: 600;
} */

/* Details *************************************/
.details {
  padding-right: 0.5rem;
}

.details .category {
  color: #585858;
  padding-bottom: 1rem;
}

.details h2 {
  font-size: 1.5rem;
}

.details h3 {
  font-size: 1.2rem;
}

.details h4 {
  font-size: 1.1rem;
}

.details .name {
  font-weight: 500;
  padding-bottom: 1rem;
}

.details .price {
  color: #ef481e;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.details .offer {
  color: #ef481e;
  font-weight: 500;
  padding-bottom: 1rem;
}

.offer span {
  color: #4b4848;
  text-decoration: line-through;
  padding-right: 1rem;
}

.details .availability {
  font-weight: 500;
  padding-bottom: 1rem;
}

.availability span {
  color: #1aab2a;
  padding-left: 1.4rem;
}

.manufacture {
  font-weight: 500;
  padding-bottom: 1.5rem;
}

.manufacture span {
  font-weight: 400;
  padding-left: 0.7rem;
}

.quantity {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}

.quantity h4 {
  font-weight: 500;
  padding-right: 1.5rem;
}

.quantity p {
  color: #585858;
  padding-left: 1rem;
}

.quantity .wrapper {
  width: 9rem;
  max-width: 10rem;
  margin: 0 10px 0 0;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #bebebe;
  border-radius: 5px;
}

.quantity .wrapper span {
  width: 30%;
  text-align: center;
  color: #585858;
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}

.quantity .wrapper span.num {
  color: black;
  font-size: 19px;
  pointer-events: none;
}

button:disabled {
  cursor: default !important;
  background-color: rgba(20, 69, 128, 0.4) !important;
  color: #ffffff !important;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;
}

.buttons button {
  /* padding: 0.5rem 3.5rem; */
  width: 10.5rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
}

.buttons .add-to-cart {
  background-color: transparent;
  color: #144580;
  border: 1px solid #bebebe;
}

.buttons .add-to-cart:hover {
  background-color: #144580;
  color: #ffffff;
  border: none;
  transform: scale(1.05);
}

.details .wishlist {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 1.5rem;
  width: fit-content;
}

.details .wishlist p {
  color: #808080;
  font-weight: 500;
  font-size: 1rem;
  margin-left: 0.5rem;
}

.details .wishlist i {
  color: #ef481e;
  font-size: 1.3rem;
  padding-right: 1rem;
}

/* Additional Information */
.additional-information {
  padding: 0.5rem 2rem;
}

.additional-information ul {
  display: flex;
  margin-bottom: 1rem;
}

hr {
  color: #c5b7b7;
}

.additional-information ul li {
  font-size: 1.1rem;
  color: #144580;
  font-weight: 600;
  padding-right: 3rem;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}

.additional-information ul li:hover {
  transform: scale(1.02);
}

.additional-information ul li:nth-child(1):before {
  content: " ";
  position: absolute;
  width: 6.5rem;
  height: 2.5px;
  bottom: -10%;
  border-radius: 7px;
  background-color: #144580;
}

.additional-information p {
  padding-bottom: 2rem;
}

/* .top-selling {
  margin-top: 0 !important;
  padding-top: 0 !important;
} */

/* Responsive */
@media (max-width: 698px) {
  body {
    overflow-x: hidden;
  }

  .main-section {
    flex-direction: column;
  }

  .main-section .picture {
    width: 100%;
  }

  /* .swiper {
        width: 90% !important;
    } */

  .picture .thumb {
    height: 85%;
    width: 80% !important;
  }

  .picture .thumbnail {
    height: 85%;
    width: 80% !important;
  }
  .main-section .details {
    padding: 1rem 1rem;
  }

  .main-section .details h2 {
    font-size: 20px;
  }

  .main-section .details h3 {
    font-size: 17px;
  }

  .main-section .details h4 {
    font-size: 16px;
  }

  .main-section .details .name {
    font-weight: 450;
    padding-bottom: 0.8rem;
  }

  .quantity p {
    display: none;
  }

  .wrapper {
    flex-direction: row;
  }

  .buttons {
    width: 90%;
    padding-top: 0.5rem;
  }

  .buttons button {
    width: 10rem;
    height: 3.1rem;
    border-radius: 7px;
    font-size: 1rem;
  }

  .additional-information ul li {
    font-size: 1.05rem;
    color: #144580;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 698px) {
  .main-section .picture {
    width: 50%;
  }

  .picture .mySwiper2 {
    height: 75%;
    width: 80% !important;
  }

  .quantity .wrapper {
    flex-wrap: nowrap;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }
}
