.box {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  height: 630px;
}

.box .img {
  width: 50%;
  background: url("/public/assets/images/Rectangle 880.svg");
  background-color: black;
  background-size: cover;
  position: relative;
}

.box .img::before {
  content: "Create Account";
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 260px;
}

.box .img button {
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  color: #ffffff;
  background-color: transparent;
  border: 2px solid #ef481e;
  border-radius: 6px;
  width: 150px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box .img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.box .loginPanel {
  max-width: 50%;
  width: 100%;
  padding: 3.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box .loginPanel h3 {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 1rem;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}

.box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.box .checkbox {
  padding-bottom: 3px;
}

.box .checkbox label {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #808080;
  margin-left: 0.3rem;
}

.box .blue {
  color: #144580;
}

.box button {
  width: 100%;
  height: 2.8rem;
}

.box .signUp {
  width: 100%;
  margin: 0.8rem auto;
  text-align: center;
}

.box .stick {
  color: #808080;
  font-size: 1rem;
  margin: 0 0.3rem;
}

.box .red {
  color: #ef481e;
}

.box .or {
  width: 100%;
  text-align: center;
  margin-bottom: 0.8rem;
  position: relative;
}

.box .or::before {
  content: "";
  position: absolute;
  width: 45%;
  height: 2px;
  background-color: #d6d3d3;
  left: 0;
  top: 50%;
}

.box .or::after {
  content: "";
  position: absolute;
  width: 45%;
  height: 2px;
  background-color: #d6d3d3;
  right: 0;
  top: 50%;
}

.box .connects {
  background: #f0f0f0;
  border-radius: 6px;
  margin-bottom: 0.8rem;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.box .connects i {
  margin-right: 1rem;
}

.box .grey {
  font-size: 13px;
  color: #808080;
}

@media (max-width: 700px) {
  .box .img {
    display: none;
  }

  .box .loginPanel {
    max-width: 100%;
    width: 100%;
    padding: 2rem;
    position: relative;
  }
}
