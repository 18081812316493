.breadCrumbs a{
    color: #000000;
}
@media (max-width: 700px) {
    .css-9vq7zl-MuiTypography-root-MuiBreadcrumbs-root{
       padding-left: 0.5rem !important;
       padding-top: 0.7rem !important;
       padding-bottom: 0.8rem !important;
    }
    .css-10sygvr-MuiSvgIcon-root{
        margin-bottom: 0rem
    }
    .css-1wuw8dw-MuiBreadcrumbs-separator{
        margin-left: 0px;
        margin-right: 0px;
    }
    .css-9vq7zl-MuiTypography-root-MuiBreadcrumbs-root a{
        font-size: 0.78rem;
        align-items: flex-end;
    }
}