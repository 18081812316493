.cardContainer {
  width: 20%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1198px) {
  .cardContainer {
    width: 25%;
  }
}

@media only screen and (max-width: 990px) {
  .cardContainer {
    width: 33%;
  }
}

/* @media only screen and (max-width: 768px) {
  .cardContainer {
    width: 50%;
  }
} */

@media only screen and (max-width: 576px) {
  .cardContainer {
    width: 50%;
    padding: 0px 0px;
  }
}
