.paginationContainer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0px;
}

.paginationContainer .Mui-selected {
  background-color: #144580 !important;
  color: #ffffff !important;
}
