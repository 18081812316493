.container {
  /* padding: 0rem 3rem; */
  padding-bottom: 1.5rem;
}

.container h1 {
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: 2rem;
}

.h1-nopt {
  padding-top: 0 !important;
}

.flex-div {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
}

@media (max-width: 992px) {
  .flex-div {
    gap: 1rem;
    justify-content: center;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .flex-div {
    justify-content: flex-start;
    gap: 1rem;
  }
}

@media (max-width: 685px) {
  .flex-div {
    gap: 1rem;
  }
}

@media (max-width: 375px) {
  .flex-div {
    gap: 0.75rem;
  }
}

.content-div {
  height: 100%;
  width: 100%;
  max-width: 15.4rem;
  max-height: 12rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  transition: 0.2s ease-out all;
}

@media (max-width: 992px) {
  .content-div {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .content-div:hover {
    box-shadow: 5px 5px 10px #000;
  }
}

.content-div img {
  width: 15.4rem;
  height: 12rem;
  object-fit: cover;
  border-radius: 10px;
}

.content-div .filter {
  position: absolute;
  background-color: #144580;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  border-radius: 10px;
}

.content-div p {
  position: absolute;
  font-weight: 600;
  top: 45%;
  color: #ffffff;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
}

.hospitals-div .content-div {
  border: 1px solid #bebebe;
  border-radius: 10px;
}

/* Doctors Div Section */
.bookSlide {
  width: fit-content !important;
}

@media (max-width: 768px) {
  .bookSlide {
    width: 46% !important;
    min-height: 150px;
    max-height: 150px;
  }
}

@media (max-width: 576px) {
  .bookSlide {
    min-height: 205px;
    max-height: 205px;
  }
}

.doctors-div {
  min-width: 300px;
  max-height: 154px;
  display: flex;
  padding: 0.5rem 0;
  border: 1px solid #bebebe;
  justify-content: space-around;
  border-radius: 5px;
  margin-right: 0.3rem;
}

.doctors-div img {
  max-width: 38%;
  height: 135px;
  min-width: 121px;
  object-fit: cover;
}

.information h3 {
  color: #144580;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding-bottom: 4px;
}

.information p {
  font-size: 12px;
  color: #747575;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 3px;
}

.information button {
  margin-top: 6px;
  padding: 0.5rem;
  border: none;
  background-color: #144580;
  border-radius: 5px;
  font-size: 13px;
  transition: all 0.2s;
  cursor: pointer;
}
.information button a {
  color: #ffffff;
}

.information button:hover {
  transform: scale(1.03);
}

/* Offers Seection */
.offers-div {
  display: flex;
  width: 45%;
  border-radius: 7px;
}

.offers-div a {
  width: 100%;
}

.offers-div img {
  height: 100%;
  width: 25%;
}

.offers-div .info-div {
  padding: 1.5rem;
}

.info-div h2 {
  font-size: 1.2rem;
  font-weight: 550;
  line-height: 30px;
}

.info-div h2 span {
  font-weight: 700;
}

.info-div p a {
  color: #144580;
  font-size: 0.9rem;
  font-weight: 600;
}

.info-div button {
  padding: 0.4rem 2rem;
  border: none;
  border-radius: 20px;
  color: #144580;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1.5rem;
  transition: all 0.2s;
  cursor: pointer;
}

.info-div button:hover {
  transform: scale(1.03);
}

.pink {
  background: #f5dcc7;
}

.mint {
  background: #dcf2ce;
}

/* Why Book Appointments Section */
.appointments {
  background: #f6f6f6;
  margin-bottom: 2rem;
  position: absolute;
}

.flex-div .appointment-div {
  background: #f6f6f6;
  border-radius: 10px;
  padding: 1.5rem;
  width: 30%;
  text-align: center;
}

.appointment-div h3 {
  color: #144580;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 0;
}

.appointment-div i {
  font-size: 2rem;
}

.appointment-div:nth-child(1) i {
  background: #fcf7c8;
  color: #ead773;
  padding: 1.5rem;
  border-radius: 50%;
}

.appointment-div:nth-child(2) i {
  background: #cae6ff;
  color: #4e96d8;
  padding: 1.5rem;
  border-radius: 50%;
}

.appointment-div:nth-child(3) i {
  background: #d4fabb;
  color: #88cf5b;

  padding: 1.5rem;
  border-radius: 50%;
}

.appointment-div p {
  text-align: justify;
  color: #757575;
  font-weight: 400;
}

/* Responsive */
@media (max-width: 700px) {
  .container {
    padding: 2rem 2rem;
  }

  .container h1 {
    font-size: 1.3rem;
    font-weight: 550;
    padding-bottom: 1rem;
  }

  .offers-div {
    width: 100%;
    overflow: hidden;
    max-height: 313px;
    margin-bottom: 1.5rem;
  }

  .offers-div img {
    height: 100%;
    width: 35%;
  }

  .info-div h2 {
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 23px;
  }

  .info-div p a {
    font-size: 0.7rem;
  }
  .info-div button {
    padding: 0.3rem 1.8rem;
    border: none;
    border-radius: 20px;
    color: #144580;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.6rem;
    transition: all 0.2s;
    cursor: pointer;
  }
  .flex-div .appointment-div {
    background: #f6f6f6;
    border-radius: 10px;
    padding: 1.5rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 700px) {
  .container {
    padding: 2rem 1rem;
  }

  .container h1 {
    font-size: 1.3rem;
    font-weight: 550;
    padding-bottom: 1rem;
  }

  .offers-div {
    width: 100%;
    overflow: hidden;
    max-height: 313px;
    margin-bottom: 1.5rem;
  }

  .offers-div img {
    height: 100%;
    width: 35%;
  }

  .info-div h2 {
    font-size: 1rem;
    line-height: 26px;
  }

  .info-div p a {
    font-size: 0.8rem;
  }
  .info-div button {
    padding: 0.3rem 1.7rem;
    border: none;
    border-radius: 20px;
    color: #144580;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 1.5rem;
    transition: all 0.2s;
    cursor: pointer;
  }
}

.bookingTopSwiper {
  padding: 1rem 0 !important;
}

@media (max-width: 576px) {
  .container {
    padding: 2rem 1rem;
  }
}
