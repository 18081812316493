.accountOutlet p strong {
  margin-left: 2px;
}

.accountOutlet form .inputs {
  display: flex;
  flex-wrap: wrap;
}

.accountOutlet form button {
  margin-left: auto;
  display: block;
  margin-right: 8rem !important;
}

.accountOutlet form input,
.accountOutlet form .genderDiv {
  margin-right: 1.5rem;
}

.genderDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
}

.genderDiv svg {
  width: 1.1rem;
  margin-right: 10px;
  color: #808080;
}

.genderInput {
  color: #808080;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;
}

.genderSelect {
  width: 24rem;
  height: 45px;
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  background-color: #ffffff;
  border: 2px solid #d6d6d6;
  border-radius: 7px;
  padding-left: 1rem;
  outline: none;
}

.starGender {
  color: #c63535;
}

.genderError {
  color: #db0b0b;
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.defaultAdd {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 24px;
  color: #808080;
}

.defaultAdd svg {
  color: rgba(239, 72, 30, 1);
  width: 1.3rem;
  margin-right: 0.6rem;
}
