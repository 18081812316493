/* Modal */
.MuiBox-root {
  position: relative;
  border-radius: 7px;
}

.MuiBox-root .css-old1by {
  /* padding: 1rem 0; */
  padding: 1rem;
}

.modal {
  padding: 2rem 3rem;
  max-height: 37rem;
  padding-bottom: 4rem;
  /* overflow: scroll; */
  overflow-y: auto;
  overflow-x: hidden;
}

.no-overflow {
  overflow: hidden;
}

.modal .modalHeader {
  display: flex;
  align-items: center;
}

.modal .modalHeader h4 {
  font-weight: 500;
  font-size: 1.3rem;
}

.modal .modalHeader svg {
  margin-right: 5px;
}

.modal .testCount {
  width: 9rem;
  height: 2.5rem;
  background-color: #e3ffe6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #1aab2a;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.modal .eReports {
  color: #757575;
  font-weight: 400;
  font-size: 1rem;
}

.modal .eReports i {
  font-size: 1.1rem;
  margin-right: 0.4rem;
}

.modal .description {
  margin: 1rem 0;
}

.modal .cartWishlist {
  color: #144580;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.modal .cartWishlist i {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.modal .cartWishlist span {
  padding: 0 1rem;
  font-size: 1.5rem;
  font-weight: 300;
  color: #d9d9d9;
}

.modal .dataDiv {
  margin-bottom: 1rem;
}

.modal .dataDiv h4 {
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0.2rem;
}

.modal .dataDiv p,
.modal .dataDiv li {
  color: #878787;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 4px;
}

.modal .dataDiv ul {
  padding-left: 16px;
}

.modal .dataDiv li {
  list-style-type: disc;
}

.modal .footData {
  position: absolute;
  bottom: -2%;
  left: 0rem;
  width: 100%;
  height: 6rem;
  background-color: #fdf3ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-radius: 7px;
}

.modal .footData-small {
  height: 4.5rem;
  justify-content: flex-end;
}

.modal .footData h4 {
  color: #ef481e;
  font-weight: 600;
  font-size: 1.35rem;
  padding-left: 2rem;
}

.modal .footData span {
  font-weight: 400;
  font-size: 1rem;
  color: #757575;
  text-decoration: line-through;
}

@media (max-width: 698px) {
  .boxModal {
    width: 95% !important;
  }

  .modal {
    padding: 1.5rem 2rem;
    max-height: 37rem;
    padding-bottom: 4rem;
  }
  .modal .footData {
    width: 100%;
    padding: 0.3rem 0.9rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }

  .modal .footData h4 {
    font-size: 1.25rem;
    padding-left: 0rem;
  }
  .modal .footData span {
    font-size: 0.9rem;
  }
  .modal .buttonGroup button {
    height: 3rem;
  }
}
