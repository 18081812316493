.custom-btn {
  margin-top: 6px;
  padding: 0.5rem 1.4rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s;
  cursor: pointer;
  white-space: nowrap;
  margin-right: 1rem;
}

.blue-btn {
  background-color: #144580;
  color: #ffffff;
}

.blue-btn-disabled {
  background-color: rgba(20, 69, 128, 0.4) !important;
  cursor: default !important;
}
