.inputContainer {
  width: 40%;
  margin-right: 16px;
}

@media (max-width: 768px) {
  .inputContainer {
    width: 100%;
  }
}

.inputContainerFluid {
  width: 85%;
  margin-right: 16px;
}

.inputLabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  position: relative;
  width: 100%;
}

.eyeIcon {
  position: absolute;
  top: 44px;
  right: 8px;
  cursor: pointer;
}

.inputLabel svg {
  color: #808080;
  height: 15.833333015441895px;
  width: 1.1rem;
  height: 1.05em;
  margin-right: 10px;
}

.label {
  color: #808080;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;
  overflow: hidden;
  white-space: nowrap;
}

.star {
  color: #c63535;
}

.input {
  /* width: 24rem; */
  width: 100%;
  height: 46px;
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  background-color: #ffffff;
  border: 2px solid #d6d6d6;
  border-radius: 7px;
  padding-left: 1rem;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  text-align: center;
  padding-left: 0 !important;
  -moz-appearance: textfield;
}

.productDetailInput {
  width: 50%;
  /* height: auto; */
  height: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  background-color: #ffffff;
  /* padding-left: 1rem; */
  padding-right: 0.3rem;
  outline: none;
  border: transparent;
  border-left: 1px solid #bebebe;
  border-right: 1px solid #bebebe;
  border-radius: 0px;
  text-align: center;
  /* border-left: none; */
  /* border-right: none; */
  /* border: transparent; */
}

/* To hide arrows in number: Chrome, Safari */
.productDetailInput::-webkit-outer-spin-button,
.productDetailInput::-webkit-inner-spin-button {
  display: none;
  margin: 0;
  padding: 0;
}

/* To hide arrows in number: Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.textarea {
  /* width: 24rem; */
  width: 100%;
  /* height: 46px; */
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  background-color: #ffffff;
  border: 2px solid #d6d6d6;
  border-radius: 7px;
  padding-left: 1rem;
  outline: none;
}

.disabled {
  background-color: rgb(241, 241, 241);
  border: 2px solid gainsboro;
}

.inputLabel .formError {
  color: #db0b0b;
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  /* position: absolute; */
  /* bottom: -27%; */
}

@media (max-width: 698px) {
  .input {
    width: 100%;
  }
  .inputLabel {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .input {
    width: 100%;
  }
  .inputLabel {
    width: 100%;
  }
}
