.box {
  /* margin: 0rem 2rem; */
  margin-bottom: 1rem;
}

.header {
  font-size: 1.5rem;
  font-weight: 600;
  /* padding-bottom: 1.5rem; */
}

.img {
  width: 100%;
  max-width: 1350px;
  object-fit: contain;
}

@media (max-width: 698px) {
  .box {
    margin: 1.3rem;
  }
}
